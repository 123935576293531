/* eslint-disable react/jsx-props-no-spreading */
import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/styles.scss';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import './confirmDeleteModal.scss'
import { useQuery } from 'react-query';
import { getExerciseTemplates } from 'api/exerciseTemplateApi';
import Svg from 'components/svg';
import ReactPlayer from 'react-player';
import { isImageType, isVideoType } from 'utils/utils';
import { HiOutlineSearch } from 'react-icons/hi';
import { searchMaxLength } from 'utils/constants';
import { isEmpty } from 'lodash';
import './selectExerciseTemplateModal.scss'

const SelectExerciseTemplateModal = (props: any) => {
    const { openModal, setOpenModal, selectedExerciseTemplate, setSelectedExerciseTemplate } = props
    const rootRef = useRef<HTMLDivElement>(null);
    const inputRef: any = useRef(null);
    const [t] = useTranslation()
    const [tempSelected, setTemplateSelected] = useState(selectedExerciseTemplate)
    const [exerciseTemplates, setExerciseTemplates] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const modalRef: any = useRef(null);

    const { data: exerciseTemplatesData, isLoading } = useQuery(
        ['getExerciseTemplate'],
        () =>
            getExerciseTemplates({
                page: 0,
                limit: 0,
            }),
    );

    useEffect(() => {
        if (exerciseTemplatesData !== undefined) {
            setExerciseTemplates(exerciseTemplatesData?.data?.entities)
        }
    }, [exerciseTemplatesData])

    useEffect(() => {
        if (searchValue !== '') {
            console.log("herererere");

            const result = exerciseTemplates?.filter((item: any) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
            setExerciseTemplates(result)
        } else {
            setExerciseTemplates(exerciseTemplatesData?.data?.entities)

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    const closeModal = () => {
        setOpenModal(false)
    };

    const handleSelectTemplate = (template: any) => {
        setTemplateSelected(template)
    }

    const handleSave = () => {
        setSelectedExerciseTemplate(tempSelected)
        closeModal()
    }
    const handleOnKeyUp = (e: any) => {
        const { key, target } = e;
        if (key === 'Enter') {
            setSearchValue(target.value);
        }
    };

    const handleOnChange = (e: any) => {
        if (isEmpty(e.target.value)) setSearchValue(e.target.value);
    };

    const handleSearch = (e: any) => {
        const { key, target } = e;
        if (key === 'Enter') {
            e.preventDefault();
            setSearchValue(target.value);
        }
    };

    return (
        <div ref={rootRef} >
            <Modal
                show={openModal}
                size="4xl"
                root={rootRef.current ?? undefined}
                onClose={closeModal}
                dismissible={true}
                className='modal-select-exercise-template'
                ref={modalRef}
            >
                <CustomModalHeader
                    title={t('Select Exercise Template')}
                    toggle={closeModal}
                />
                <Modal.Body className='modal-body'>
                    <div className="relative text-search mb-4">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2 h-34">
                            <HiOutlineSearch strokeWidth={3} className="w-4 h-4" />
                        </span>
                        <input
                            ref={inputRef}
                            className="w-full focus:oun pl-8 text-sm text-search border-1 rounded-md border-gray-300 bg-white h-34 focus:outline-none mr-5 action-search-template"
                            type="search"
                            name="search"
                            maxLength={searchMaxLength}
                            placeholder={t('Search')}
                            onChange={e => handleOnChange(e)}
                            onKeyUp={e => handleOnKeyUp(e)}
                            onKeyDown={e => handleSearch(e)}

                        />
                    </div>
                    {!isLoading && (
                        <div className='h-[50vh] overflow-auto'>
                            {exerciseTemplates?.map((item: any) => {
                                return (
                                    <div className={`flex p-2 ${tempSelected?.id === item?.id ? 'bg-light-blue hover:bg-light-blue' : 'hover:bg-bg-hover'}`} onClick={() => handleSelectTemplate(item)}>
                                        <div>
                                            {(item?.videoUrl || (item?.file?.url && isVideoType(item?.file?.type))) && (
                                                <div className='relative w-full rounded-lg bg-black' style={{ width: 80, maxWidth: 80, maxHeight: 60 }}>
                                                    {item?.videoUrl ? <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                                        <Svg name='youtube-play-icon' className='w-[25px] h-[25px]' />
                                                    </span> : <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                                        <Svg name='play-icon' className='w-[25px] h-25px]' />
                                                    </span>}

                                                    <ReactPlayer
                                                        controls={false}
                                                        playIcon={<button className='hidden'>{''}</button>}
                                                        playing={false}
                                                        className="react-player rounded-lg"
                                                        width={80}
                                                        height={60}
                                                        url={item?.videoUrl ? item?.videoUrl : item?.file?.url}
                                                        light={!!item?.videoUrl}
                                                        style={{ borderRadius: 8 }}
                                                    />
                                                </div>
                                            )}

                                            {item?.file?.url && isImageType(item?.file?.type) &&
                                                (
                                                    <div className="rounded-lg border border-gray-300 relative" style={{ width: 80, height: 60 }}>
                                                        <img className="preview object-cover rounded" style={{ width: 80, height: 60 }} src={item?.file?.url} alt="avatar" />
                                                    </div>
                                                )}
                                            {!item?.file?.url && !item?.videoUrl &&
                                                (
                                                    <div className="rounded-lg flex justify-center items-center border relative bg-[#EDEDED]" style={{ width: 80, height: 60 }}>
                                                        <Svg name='empty-image' className='w-[25px] h-25px]' />
                                                    </div>
                                                )}
                                        </div>
                                        <div className='px-2 flex flex-col justify-center'>
                                            <p className='font-semibold text-sm text-black'>{item.name}</p>
                                            <p className='text-[#6B7280] font-normal text-xs line-clamp-2'>
                                                {item.description}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    <GroupButton
                        className="items-center justify-center pt-1 pb-2"
                        buttons={[
                            {
                                type: 'button',
                                text: t('modal.cancel'),
                                classType: 'white',
                                dataDismiss: "modal",
                                action: () => closeModal(),
                            },
                            {
                                type: 'button',
                                text: t('Save'),
                                classType: 'blue',
                                dataDismiss: "modal",
                                action: () => handleSave(),

                            },
                        ]}
                    />
                </Modal.Body>
            </Modal>
        </div >)
}
export default SelectExerciseTemplateModal