import Button from 'components/button/button';
import { useTranslation } from 'react-i18next';

const ListEmpty = (props: any) => {
    const { handleAddClick, buttonName, note, title, className, icon } = props;
    const [t] = useTranslation();
    return (
        <div className={`bg-white border border-gray-200 rounded-sm flex flex-col items-center p-8 ${className || ''}`}>
            {icon}
            <p className="text-on-primary-container font-semibold text-xl mt-7 text-header">{title}</p>
            <p className="font-normal text-gray-400 mt-2 text-base">{note}</p>
            {handleAddClick && (
                <div className="mt-6 flex justify-center">
                    <Button color="primary" label={buttonName} onClick={handleAddClick} />
                </div>
            )}
        </div>
    );
};
export default ListEmpty;
