/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { routePaths } from 'utils/constants';
import { toast } from 'react-toastify';
import './patientProfile.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tabs, TabsRef } from 'flowbite-react';
import Banner from 'components/banner/banner';
import { messageErrors } from 'utils/utils';
import { getUserById } from 'api/userApi';
import Programs from './programs';
import PatientDashboard from 'pages/patients/dashboard';

const TABS = {
    PROGRESS: 'progress',
    PROGRAMS: 'program'
}

const PatientProfile = (props: any) => {
    const [t] = useTranslation();
    const [patient, setPatient]: any = useState();
    const params: any = useParams();
    const tabsRef = useRef<TabsRef>(null);
    const location = useLocation();
    const [tab, setTab] = useState(location?.state?.tab || TABS.PROGRAMS);
    const navigate = useNavigate()

    const { data, isLoading } = useQuery(['getPatientProfile', params?.id], () => getUserById(params?.id), {
        retry: false,
        staleTime: Infinity,
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });
    const handleBackPatientList = () => {
        navigate(routePaths.THERAPIST_PATIENTS);
    }

    useEffect(() => {
        if (data?.data) setPatient(data?.data);
        // eslint-disable-next-line
    }, [data]);

    return (
        <div className="flex flex-col patient-profile w-full h-full">
            {patient && (
                <>
                    <Banner handleBack={handleBackPatientList} targetData={patient} isWrite={false} labelId="Patient ID" isPatient />
                    <div className="patient-infor h-full flex">
                        {/* eslint-disable-next-line  */}
                        <Tabs.Group ref={tabsRef} aria-label="Tabs with underline" style="underline" className="tab-button w-full">
                            <Tabs.Item active={tab === TABS.PROGRAMS} title={t('patientProfile.programs')}>
                                <Programs />
                            </Tabs.Item>
                            <Tabs.Item active={tab === TABS.PROGRESS} title={t('patientProfile.progress')}>
                                <PatientDashboard patient={patient} isTherapistSite />
                            </Tabs.Item>
                        </Tabs.Group>
                    </div>
                </>
            )}
        </div>
    );
};

export default PatientProfile;
