/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { isEmpty, map, omit, values } from 'lodash';
import GroupButton from 'components/button/groupButton';
import { Modal } from 'flowbite-react';
import { themModal } from 'utils/theme';
import CustomModalHeader from 'components/modal/customModalHeader';
import './addProgramModal.scss'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'components/select';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';
import { createProgramTemplate, getProgramTemplates } from 'api/programTemplateApi';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { messageErrors } from 'utils/utils';
import { createProgram } from "api/programApi";
import InputText from 'components/inputText';
import { ProgramStatusOptions } from 'utils/constants';

const AddProgramByNewBlankCardModal = (props: any) => {
    const { isTemplate = false, openBlankCardModal, setOpenBlankCardModal, headerTitle, type, roles, assignRoles, setOpenModal } = props;
    const [t] = useTranslation();
    const [selectedStatus, setSelectedStatus] = useState(ProgramStatusOptions[0])
    const rootRef = useRef(null);
    const { userInfo } = useSelector(userSelector);
    const queryClient = useQueryClient();
    const params = useParams()
    const location = useLocation();
    const navigate = useNavigate()

    const ValidateSchema = Yup.object().shape({
        name: Yup.string().required(t('userManagementPage.requiredField')),
    });

    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema),

    });

    const {
        formState: { errors },
        handleSubmit,
    } = hookForm

    const handleClose = () => {
        setOpenBlankCardModal(false);
    };

    const handleSelectStatus = (option: any) => {
        const status: any = [option]
        setSelectedStatus(status)
    }
    const handleMutation = (payload: any) => {
        return !isTemplate ? createProgram(payload) : createProgramTemplate(payload)
    }

    const mutationCreateProgram = useMutation('createProgram', {
        mutationFn: handleMutation,
        onSuccess: (data) => {
            const message: string = t('createdProgram');
            toast.success(message);
            if (!isTemplate) {
                queryClient.invalidateQueries('getPatientPrograms', { refetchActive: true }, { cancelRefetch: true });
                queryClient.invalidateQueries('getProgramById', { refetchActive: true }, { cancelRefetch: true });
                queryClient.invalidateQueries('getAllPatientPrograms', { refetchActive: true }, { cancelRefetch: true });
            } else {
                queryClient.invalidateQueries('getProgramTemplates', { refetchActive: true }, { cancelRefetch: true });
            }

            setOpenBlankCardModal(false)
            setOpenModal(false)
              if (!isTemplate) {
                navigate(`${location.pathname}/program-detail?programId=${data?.data?.id}`)
              } else {   navigate(`${location.pathname}/program-template-detail?programTemplateId=${data?.data?.id}`)}

        },
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const onSubmit = (value: any) => {
        const payload = {
            ...value,
            patientId: params.id
        }
        mutationCreateProgram.mutate(payload)
    };

    return (
        <div ref={rootRef}>
            <Modal
                className="modal-add-program-template"
                show={openBlankCardModal}
                size="md"
                root={rootRef.current ?? undefined}
                onClose={() => handleClose()}
                dismissible={true}
                theme={themModal}
            >
                <CustomModalHeader title={headerTitle} toggle={() => handleClose()} />
                <Modal.Body theme={{ base: 'flex-1 pb-6 pt-3 px-12' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`${errors?.lastName && 'border-error'} pt-4 pb-4`}>
                            <InputText hookForm={hookForm} placeholder={t('Program Name')} name="name" isHolderLabel={true}
                            />
                        </div>
                        {!isTemplate && (
                            <div>
                                <Select
                                    options={ProgramStatusOptions}
                                    onChange={handleSelectStatus}
                                    placeholder={t('Status')}
                                    value={selectedStatus}
                                    errors={errors}
                                    name="status"
                                    isMulti={false}
                                    isHolderLabel={false}
                                />
                            </div>
                        )}
                        <GroupButton
                            className="items-center justify-center pt-5 pb-2"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => handleClose(),
                                },
                                {
                                    type: 'submit',
                                    text: t('modal.continue'),
                                    classType: 'blue',
                                    isLoading: false,
                                },
                            ]}
                        />
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default AddProgramByNewBlankCardModal;
