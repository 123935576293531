import { Dropdown } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import { checkPermission, checkRole, getNavbarUrl } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import { pageType, permissionsOrg, permissionsSite, routePaths, roleName } from 'utils/constants';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import './userProfile.scss';
import { size } from 'lodash';
import { userAction } from '../redux/actions';
import Avatar from './GroupAvatar/Avatar';

const UserProfile = () => {
  const isUseSubscription = process.env.REACT_APP_IS_USE_SUBSCRIPTION === 'true';
  const dispatch = useDispatch();
  const ref = useRef<any>();
  const { organizationId } = useSelector(orgSelector);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const { userRole, firstName, lastName, emailAddress } = userInfo;
  const { handleLogout } = useUser();
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const checkOrgAdminRole = checkRole(userInfo, [roleName.ORGANIZATION_ADMIN], organizationId);
  const checkAdminRole = userInfo?.roles.find((role: any) => role.displayName === roleName.SITE_ADMIN)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShowDropDown(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isShowDropDown]);

  const handleOpenModalProfile = () => {
    const isOpenModal: any = true;
    dispatch(userAction.setOpenProfileModal(isOpenModal));
  };
  return (
    <div className="user-profile">
      <div className={`relative user-dropdown flex items-center`} ref={ref} onClick={() => setIsShowDropDown(!isShowDropDown)} data-testid={`test-user-profile`}>
        <div className="flex justify-between items-center w-full">
          <Avatar item={userInfo} /> <div className="p-4 text-white text-xs font-normal uppercase">{userInfo.firstName} {userInfo.lastName}</div>
        </div>
        <div className={`${!isShowDropDown && 'hidden'} dropdown-item text-sm`}>
          <div className="flex p-3">
            <div>
              <Avatar item={userInfo} />
            </div>
            <div className="ml-3 flex flex-col justify-center">
              <span className="block capitalize font-medium">{`${firstName} ${lastName}`}</span>
              <span className="block truncate text-xs text-gray-500 text-right">{emailAddress}</span>
            </div>
          </div>
          {userInfo.isActive && (
            <>
              <Dropdown.Divider /><div className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer hover:bg-gray-100 cursor-pointer" onClick={handleOpenModalProfile}>
                {t('navbar.profile')}
              </div>
              {isUseSubscription && (
                <div className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer" onClick={() => navigate(routePaths.MY_SUBSCRIPTIONS)}>
                  {t('navbar.mySubscriptions')}
                </div>
              )}
              {checkPermission(userInfo, pageType.ORGANIZATION, permissionsOrg, organizationId) && size(userInfo.organizations) > 0 && (checkOrgAdminRole || checkAdminRole) && (
                <div
                  className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer"
                  onClick={() => navigate(getNavbarUrl(userInfo, pageType.ORGANIZATION, organizationId))}
                >
                  {t('navbar.organizationSettings')}
                </div>
              )}
              {checkPermission(userInfo, pageType.SITE, permissionsSite, organizationId) && checkAdminRole && (
                <div
                  className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer"
                  onClick={() => navigate(getNavbarUrl(userInfo, pageType.SITE, organizationId))}
                >
                  {t('navbar.admin')}
                </div>
              )}
              {userRole === roleName.INTEGRATOR && (
                <>
                  <Dropdown.Divider />
                  <div className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer" onClick={() => navigate(routePaths.MY_TOKENS)}>
                    {t('navbar.integrator')}
                  </div>
                </>
              )}
            </>
          )}

          <Dropdown.Divider />
          <div className="px-4 py-2 text-gray-900 hover:bg-gray-100 cursor-pointer" onClick={() => handleLogout()}>
            <span className="text-red-500">{t('logout')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserProfile;
