import { Spinner } from 'flowbite-react';

const SpinnerComponent = () => {
  return (
    <div className="fixed w-full h-full bg-black top-0 left-0 z-50 opacity-50">
    <span className="spinner">
      <Spinner aria-label="Center-aligned spinner example" />
    </span>
    </div>
  );
};
export default SpinnerComponent;
