// eslint-disable-next-line
import { myTokenType, orgTypes, userType } from './proptypes';

const initUser: userType = {
  id: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  roles: [],
  isActive: true,
  created: new Date(),
};

const initOrganization: orgTypes = {
  id: '',
  name: '',
  userOrganizations: [],
  isActive: true,
};

const initToken: myTokenType = {
  token: '',
  expiredDate: '',
  status: 'Active',
};
export { initUser, initOrganization, initToken };
