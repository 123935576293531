/* eslint-disable react/jsx-props-no-spreading */

import './CheckboxCustom.scss'
const CheckboxCustom = (props: any) => {

    const { day, hookForm, name, index } = props

    const {
        formState: { errors },
        register,
    } = hookForm;

    return (
        <div className="weekDays-selector">
            <input
                {...register(`${name}`)}
                key={`${name}`}
                type="checkbox"
                id={`${name}`}
                name={`${name}`}
                className="weekday" />
            <label className={`${name} text-sm font-semibold flex justify-center items-center`}
                htmlFor={`${name}`}>{day}</label>
        </div>
    )
}
export default CheckboxCustom