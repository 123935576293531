import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import { toast } from 'react-toastify';
import { messageErrors } from 'utils/utils';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import './organizationTherapistModal.scss'
import { isEmpty, omitBy } from 'lodash';
import InputText from 'components/inputText';
import { assignPatient, createOrganizationUser, getUsersByEmail } from 'api/userApi';

type organizationModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    headerTitle?: string,
    queryClient?: any,
    setCurrentPage?: any,
    currentPage?: number,
    roles?: any,
    therapistId: string
};
const AssignPatientModal = (props: organizationModalProps) => {
    const { openModal, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage, therapistId } = props;
    const { fetchCurrentUser } = useUser();
    const [t] = useTranslation();
    const rootRef = useRef<HTMLDivElement>(null);


    const handleAssignPatient = async (values: any) => {
        await assignPatient(values);
    };

    const ValidateSchema = Yup.object().shape({
        patientEmailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
    });

    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema)
    });

    const {
        formState: { errors },
        handleSubmit,
        reset,
    } = hookForm

    const mutation = useMutation('assignPatient', {
        mutationFn: handleAssignPatient,
        onSuccess: () => {
            toggleHandler()
            if (currentPage !== 0) setCurrentPage(0);
            queryClient.invalidateQueries('getOrganizationTherapists', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getOrganizationPatients', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getOrganizationTherapistPatients', { refetchActive: true }, { cancelRefetch: true });

            const message: string = t('assignedPatient')
            toast.success(message);
            fetchCurrentUser();
        },
        onError: (error) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        }
    });

    const onSubmit = async (values: any) => {

        let payload = {
            ...values,
            therapistId
        };
        payload = omitBy(payload, v => v === '')
        mutation.mutate(payload);
    };

    const toggleHandler = () => {
        setOpenModal(!openModal)
        reset()
    }

    useEffect(() => {
        reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div ref={rootRef} className="relative">
            <Modal
                className='modal-organization-therapist'
                show={true}
                size="md"
                popup={true}
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={true}>
                <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
                <Modal.Body className='modal-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`${errors.emailAddress && 'border-error'} pt-4 px-5`}>
                            <InputText hookForm={hookForm} placeholder={`${t('userProfilePage.email')} *`} name="patientEmailAddress" isHolderLabel={true} />

                        </div>
                        <div className="flex justify-center items-center pt-6 px-5 ">
                            <GroupButton
                                className="items-center justify-center pb-2"
                                buttons={[
                                    {
                                        type: 'button',
                                        text: t('modal.cancel'),
                                        classType: 'white',
                                        action: () => toggleHandler(),
                                    },
                                    {
                                        type: 'submit',
                                        text: t('modal.save'),
                                        classType: 'blue',
                                        isLoading: mutation.isLoading,
                                    },
                                ]}
                            />
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
        </div>
    );
};
export default AssignPatientModal;
