import { refreshTokenKey, tokenKey } from '../utils/constants';
import { getCookie, setCookie } from 'utils/cookie';

export const setToken = (token: string, expire?: any) => {
  setCookie(tokenKey, token, expire);
};

export const getToken = () => {
  let token = getCookie(tokenKey);
  return token;
};

export const setRefreshToken = (value: string, expire?: any) => {
  setCookie(refreshTokenKey, value, expire);
};

export const getRefreshToken = () => {
  return  getCookie(refreshTokenKey);
};
