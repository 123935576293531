import { Table } from 'flowbite-react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { sortByTypeType } from 'utils/constants';

const TableCustom = ({ children, className = '', onClick = () => { } }: any) => {
  return (
    <Table className={` ${className}`} onClick={onClick}>
      {children}
    </Table>
  );
};

TableCustom.Head = ({ children, className = '', onClick = () => { } }: any) => {
  return (
    <Table.Head className={`normal-case border-b-2 ${className}`} onClick={onClick}>
      {children}
    </Table.Head>
  );
};

TableCustom.HeadCell = ({ children, className = '', onClick = () => { }, action, order }: any) => {
  const { ASC } = sortByTypeType;
  return (
    <Table.HeadCell className={`bg-white font-medium text-on-primary-container py-2 px-4 text-header ${className}`} onClick={onClick}>
      <button className="flex items-center" onClick={action}>
        {children} {order && (order === ASC ? <HiChevronDown className="w-4 h-4" /> : <HiChevronUp className="w-4 h-4" />)}
      </button>
    </Table.HeadCell>
  );
};

TableCustom.Body = ({ children, className = '', onClick = () => { } }: any) => {
  return (
    <Table.Body className={`${className}`} onClick={onClick}>
      {children}
    </Table.Body>
  );
};

TableCustom.Row = ({ children, className, onClick = () => { } }: any) => {
  return (
    <Table.Row className={`bg-white text-base hover:bg-hover-5 border-b text-sm ${className || ''}`} onClick={onClick}>
      {children}
    </Table.Row>
  );
};

TableCustom.Cell = ({ children, className, onClick = () => { } }: any) => {
  return (
    <Table.Cell className={`py-2.5 px-4 text-on-primary-container text-sm ${className || ''}`} onClick={onClick}>
      {children}
    </Table.Cell>
  );
};

export default TableCustom;
