import { Button, Card } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { routePaths } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import SidebarComponent from 'components/layouts/sidebar';
import Header from 'components/header';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';

const ContactSupportPage = () => {
  const [t] = useTranslation();
  const navigate = useNavigate()
    const { userInfo } = useSelector(userSelector);

  return (
    <>
      <div className="w-full flex flex-row justify-center">
      <SidebarComponent isDashboard={true} />
      <div className="w-full h-full flex flex-col justify-between bg-gray-50">
        <div className="w-full mt-dashboard-mb shadow-md">
          <Header />
        </div>
        {!userInfo?.isActive && (
          <div className="h-full pt-28">
            <p className="text-2xl font-semibold text-center mb-4">{t('Your account has been disabled.')}</p>
             <p className="text-base font-normal text-gray-400 text-center	">{t('Please contact')} <a className='text-primary font-semibold text-base' href="mailto:support@hedgehealth.io">{t('support')}</a></p>
          </div>
        )}
      </div>
      <div className="h-screen block" />
    </div>
    </>
  );
};
export default ContactSupportPage;
