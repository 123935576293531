import { camelCase, map, split } from 'lodash';
import { getAvatarColor } from '../../utils/utils';
import { HiUser } from 'react-icons/hi';
import { useEffect, useState } from 'react';

const Avatar = (props: any) => {
  const { item, className, isSmall = false } = props;

  const nameArr = split(item?.name || `${camelCase(item?.firstName)} ${camelCase(item?.lastName)}`, ' ', 2);
  const name = map(nameArr, str => str[0]).join('');

  const [urlError, setUrlError] = useState<boolean>(false)
  const [url, setUrl] = useState("")
  useEffect(() => {
    const urlImage = item?.url || item?.image?.url
    setUrl(urlImage)
    if (!urlImage) {setUrlError(true)} else setUrlError(false)
  }, [item])
  
  const backgroundAvatar = getAvatarColor(item);
  const textImg = className?.includes('xl') ? 'text-base' : 'text-xs'


  return (
    <div
      className={`${className || 'w-34 h-34 text-xs'} bg-border flex items-center justify-center rounded-md overflow-hidden`}
    >
      {url  && !urlError && <img className={textImg} src={url} alt="avatar" style={{ display: urlError ? 'none' : undefined }} onError={() => setUrlError(true)} />}
      {(!url || urlError) && name && <span className={`text-tertiary font-semibold ${isSmall ? 'text-xs' : ''} uppercase`}>{name}</span>}
      {(!url || urlError) && !name && <HiUser className="text-tertiary w-4/6 h-4/6 text-gray-500" />}
    </div>
  );
};

export default Avatar;
