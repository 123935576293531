import { Button as FlowbiteButton } from 'flowbite-react';
import './button.scss';

const Button = (props: any) => {
  const { type, onClick, color, outline, disabled, label, children } = props;
  let className = ' transition button-custom hover:bg-primary text-sm font-normal font-medium ' + (props.className || '');
  if (color) {
    if (!outline) className = ` bg-${color} enabled:hover:bg-${color}` + className;
    if (outline) className = ` bg-white border border-${color} text-${color}` + className;
  }
  const theme = {
    base: '',
    color: { info: 'text-white' },
    outline: { pill: { off: 'rounded-md', on: 'rounded-full' } },
    pill: { off: 'rounded-md', on: 'rounded-full' },
  };
  return (
    <FlowbiteButton theme={theme} className={className} type={type || 'button'} onClick={onClick} disabled={disabled}>
      {label || children}
    </FlowbiteButton>
  );
};

export default Button;
