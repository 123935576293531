// eslint-disable-next-line
import base from './baseApi';
import { omitBy } from 'lodash';
import { createSearchParams } from 'react-router-dom';

const { urls, methods, execute } = base();
const getExercises = (queryFilter: any) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.exercises.exercises}?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const getExercisesChart = (queryFilter: any) => {
  const method = methods.GET;
  const initParams: any = omitBy(queryFilter, v => v === '');
  const params = createSearchParams(initParams);

  const url = `${urls.exercises.exercisesChart}?${params.toString()}`;
  const response = execute(method, url, { authorization: true });

  return response;
};

const markExerciseComplete = (payload: any) => {
  const method = methods.POST;
  const url = `${urls.exercises.exerciseCompletion}`;
  const response = execute(method, url, { authorization: true }, payload);

  return response;
};

const uploadFile = (payload: { file?: any }) => {
  const { file } = payload;
  const method = methods.POST;
  const url = `${urls.exercises.uploadFile}`;
  const response = execute(method, url, { authorization: true }, file);
  return response;
};

const updateExercise = (exerciseId: string, payload: any) => {
  const method = methods.PUT;
  const url = `${urls.exercises.exercises}/${exerciseId}`;
  const response = execute(method, url, { authorization: true }, payload);
  return response;
};


const deleteExercise = (exerciseId: string) => {
  const method = methods.DELETE;
  const url = `${urls.exercises.exercises}/${exerciseId}`;
  const response = execute(method, url, { authorization: true });
  return response;
};


export {
  getExercises,
  getExercisesChart,
  markExerciseComplete,
  uploadFile,
  updateExercise,
  deleteExercise
};
