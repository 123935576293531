import { codeVerifier } from 'utils/constants';
import base from './baseApi';
import axios from 'axios';

const { urls, methods, execute } = base();

const resendEmailVerification = () => {
  const method = methods.POST;
  const url = urls.user.resendEmailVerification;

  return execute(method, url, { authorization: true });
};

const auth2Token = (code: any) => {
  const redirectUri = window.location.origin;
  const domain = process.env.REACT_APP_OAUTH_DOMAIN || '';
  const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID || '';
  const tokenRequestBody = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: clientId,
    redirect_uri: redirectUri,
    code_verifier: codeVerifier,
    code,
    scope: 'openid profile email',
  });
  const requestConfig = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
  return axios.post(`${domain}/oauth2/token`, tokenRequestBody.toString(), requestConfig);
};

const getLogoutUrl = () => execute(methods.GET, '/authentication/getLogoutUrl');

export { resendEmailVerification, auth2Token, getLogoutUrl };
