import { useTranslation } from 'react-i18next';
import { exportFileName, defaultpageCount, permissionKeys, sortByTypeType } from 'utils/constants';
import { myTokenType } from 'utils/proptypes';
import { isEmpty, isEqual } from 'lodash';
import { initToken } from 'utils/initData';
import { useState, useEffect } from 'react';
import './myTokensManagement.scss';
import { getMyTokens, createToken, deleteToken } from 'api/myTokenApi';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import PaginateTable from 'components/table/paginate';
import { toast } from 'react-toastify';
import NoResult from 'components/commonComponent/noResult';
import SpinnerComponent from 'components/spinner';
import moment from 'moment';
import { HiOutlineDuplicate, HiOutlineTrash } from 'react-icons/hi';
import { checkPermission, copyToClipboard, exportToFile, messageErrors } from 'utils/utils';
import ActionTable from 'components/table/actionTable';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import '../../styles/styles.scss';
import Actions from '../../components/actions';
import Sort from 'components/table/sort';

const MyTokens = (props: any) => {
  const { WRITE_AUTHEN } = permissionKeys;
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalEntities, setTotalEntities] = useState(0);
  const [myTokensData, setMyTokensData] = useState([]);
  const [allTokens, setAllTokens] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortByType, setSortByType] = useState('');
  const [pageCount, setPageCount] = useState(defaultpageCount);

  const { data, isLoading, isFetching } = useQuery(
    ['getMyTokens', currentPage, searchValue, sortBy, sortByType, pageCount],
    () => getMyTokens({ page: currentPage, limit: pageCount, searchQuery: searchValue, sortBy, sortByType }),
    {
      staleTime: Infinity,
      onSuccess: ({ data }) => setTotalEntities(data.totalEntities),
      onError: () => setMyTokensData([]),
    },
  );

  const getAllToken = useMutation('getAllTokens', {
    mutationFn: getMyTokens,
    onSuccess: ({ data }) => setAllTokens(data.entities),
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const { mutate: addToken } = useMutation('addToken', {
    mutationFn: createToken,
    onSuccess: () => {
      const message: string = t('myTokensManagement.addTokenSuccessfully');
      toast.success(message);
      setCurrentPage(0);
      const reloadMyTokens = async () => {
        await queryClient.invalidateQueries('getMyTokens');
      };
      reloadMyTokens().catch(() => { });
    },
  });

  const { mutate: deleteTokenById } = useMutation('deleteToken', {
    mutationFn: deleteToken,
    onSuccess: () => {
      const message: string = t('myTokensManagement.deleteTokenSuccessfully');
      toast.success(message);
      setCurrentPage(0);
      const reloadMyTokens = async () => {
        await queryClient.invalidateQueries('getMyTokens');
      };
      reloadMyTokens().catch(() => { });
    },
  });

  const handleOnClickExport = () => {
    if (!isEqual(myTokensData[0], initToken) && !isEmpty(myTokensData)) {
      getAllToken.mutate({ page: 0, limit: totalEntities, searchQuery: searchValue });
    }
  };

  const handleSearch = (value: any) => {
    setSearchValue(value);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (data !== undefined) {
      const dataTemp = data ? data?.data?.entities : [];
      setMyTokensData(dataTemp);
    }
  }, [data]);

  useEffect(() => {
    setTotalEntities(data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (!isEmpty(allTokens)) {
      const exportedData = allTokens.map((token: any) => ({ Token: token.token, ExpireDate: token.expiredDate }));
      exportToFile(exportedData, exportFileName.TOKENS);
    }
    // eslint-disable-next-line
  }, [allTokens]);

  const showDate = (date: any) => {
    const formatedDate = moment(date).format('ddd, MMM DD YYYY');
    if (new Date(date) < new Date()) {
      return <p className="text-expired">{`${t('myTokensManagement.expiredOn')} ${formatedDate}`}</p>;
    } else return <p>{`${t('myTokensManagement.expiresOn')} ${formatedDate}`}</p>;
  };

  const handleDeleteToken = (item: any) => {
    deleteTokenById(item.id);
  };

  const handleHeaderSort = (field: string) => {
    setSortBy(field);
    let type = '';
    if (field !== sortBy) {
      type = sortByTypeType.ASC;
    } else if (sortByType === sortByTypeType.ASC) {
      type = sortByTypeType.DESC;
    } else {
      setSortBy(type);
    }
    setSortByType(type);
  };

  return (
    <div className="w-full my-tokens">
      <div className='py-5'>
        <ActionTable
          placeholderSearch={t('myTokensManagement.searchToken')}
          buttonName={t('myTokensManagement.addToken')}
          handleAddClick={checkPermission(userInfo, props.type, [WRITE_AUTHEN], organizationId) && addToken}
          handleSearch={handleSearch}
        />
      </div>
      {!isEqual(myTokensData[0], initToken) && totalEntities === 0 && !isLoading && <NoResult />}

      {!isEqual(myTokensData[0], initToken) && totalEntities > 0 && (
        <>
          <div className="overflow-x-auto relative">
            <table className="w-full text-sm text-left dark:text-gray-400">
              <thead className="text-sm text-gray-600 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    scope="col"
                    className="py-3 px-4"
                    onClick={() => {
                      handleHeaderSort('token');
                    }}
                  >
                    <div className="flex items-center">
                      {t('myTokensManagement.myTokens')}
                      <Sort check={sortBy === 'token'} sortByType={sortByType} />
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-4 hidden-mobile-tablet  cursor-pointer"
                    onClick={() => {
                      handleHeaderSort('expiredDate');
                    }}
                  >
                    <div className="flex items-center">
                      {t('myTokensManagement.expireDate')}
                      <Sort check={sortBy === 'expiredDate'} sortByType={sortByType} />
                    </div>
                  </th>
                  <th scope="col" className="py-3 w-6" />
                </tr>
              </thead>
              <tbody>
                {myTokensData?.map((item: myTokenType, key) => {
                  return (
                    <tr
                      key={`${item.token}-list-tokens`}
                      className="font-medium text-gray-900 bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
                      data-testid={`table-row-element-${key}`}
                    >
                      <td className="py-3 px-4 break-word dark:text-white font-semibold token-code">
                        <div className="flex flex-row items-center">
                          <div>{item.token}</div>
                          <div className="copy-icon pl-3 cursor-pointer" data-testid={`test-icon-copy-${key}`}>
                            <HiOutlineDuplicate size={25} onClick={() => copyToClipboard(item.token, t('myTokensManagement.copiedToken'))} />
                          </div>
                        </div>
                      </td>
                      <td className="py-3 px-4 hidden-mobile-tablet font-normal dark:text-white token-expire-date">{showDate(item.expiredDate)}</td>
                      <td className="py-3">
                        {checkPermission(userInfo, props.type, [WRITE_AUTHEN], organizationId) && (
                          <Actions>
                            <Actions.Item
                              show
                              icon={<HiOutlineTrash className="w-5 h-5" />}
                              action={() => handleDeleteToken(item)}
                              label={t('myTokensManagement.delete')}
                            />
                          </Actions>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {isLoading && <SpinnerComponent />}

          <PaginateTable
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalEntities={totalEntities}
            isLoadingTable={isLoading}
            exportHandler={handleOnClickExport}
            pageCount={pageCount}
            setPageCount={setPageCount}
          />
        </>
      )}
    </div>
  );
};
export default MyTokens;
