import './assignTherapistModal.scss'
import { assignTherapist, getOrganizationUsers } from 'api/userApi';
import { components } from 'react-select';
import { messageErrors } from 'utils/utils';
import { Modal } from 'flowbite-react';
import { orgSelector, userSelector } from 'redux/selectors';
import { roleName } from 'utils/constants';
import { TherapistType } from 'types/therapistType';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import * as Yup from 'yup';

import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import LabelUser from 'components/labelUser';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'components/select';
import SpinnerComponent from 'components/spinner';
import CTALink from 'components/cta-link';

type AssignTherapistModalProps = {
   isMyPatient: boolean
   onError?: Function
   onSuccess?: Function
   openModal: boolean
   patientId: string
   setOpenModal: Function
}

type TherapistOption = {
   therapist: TherapistType
   label: string
   value: string
}

const getFullName = (item: any) => `${item?.firstName || ''} ${item?.lastName || ''}`.trim();

const AssignTherapistModal: React.FC<AssignTherapistModalProps> = (props) => {
   const { isMyPatient, onSuccess, onError, openModal, setOpenModal, patientId } = props;

   const { organizationId } = useSelector(orgSelector);

   const rootRef = useRef<HTMLDivElement>(null);
   const [t] = useTranslation();
   const { userInfo } = useSelector(userSelector);
   const queryClient = useQueryClient()
   const [selectedTherapist, setSelectedTherapist] = useState<TherapistOption | null>(null)
   const [therapistOptions, setTherapistOptions] = useState<TherapistOption[]>([])
   const [isLoading, setIsLoading] = useState(false);

   const ValidateSchema = Yup.object().shape({
      therapist: Yup.string().required(t('requiredField'))
   });

   const hookForm = useForm({
      mode: "onChange",
      resolver: yupResolver(ValidateSchema)
   });

   const {
      formState: { errors },
      handleSubmit,
      reset,
   } = hookForm

   const handleAssignTherapist = async (values: any) => {
      await assignTherapist(values);
   };

   const mutation = useMutation('assignTherapist', {
      mutationFn: handleAssignTherapist,
      onSuccess: () => {
         const message: string = t('assignedTherapist')
         toast.success(message);

         // Dispatch success
         onSuccess?.()
         setIsLoading(false);
         queryClient.invalidateQueries("getPatients")
      },
      onError: (error) => {
         const message: string = messageErrors(error, t);
         toast.error(message);

         // Dispatch error
         onError?.()
         setIsLoading(false);
      }
   });

   const { data, isFetching } = useQuery(
      ['getOrganizationTherapists', organizationId],
      () =>
         getOrganizationUsers({
            page: 0,
            limit: 9999,
            roleNames: `${roleName.THERAPIST}, ${roleName.ORGANIZATION_ADMIN}`,
         }),
      {
         onSuccess: (data: any) => {
            if (data?.data?.entities?.length) {
               const { entities } = data?.data
               const therapists: TherapistOption[] = entities.map((entity: any) => {
                  const opt: TherapistOption = {
                     therapist: {
                        emailAddress: entity.emailAddress,
                        firstName: entity.firstName,
                        lastName: entity.lastName,
                        image: entity.image
                     },
                     label: getFullName(entity),
                     value: entity.firstName + ' | ' + entity.lastName + ' | ' + entity.emailAddress // For autocomplete search
                  }

                  return opt
               })

               setTherapistOptions(therapists)
            }
         },
         onError: () => setTherapistOptions([]),
         staleTime: Infinity,
      },
   );

   useEffect(() => {
      if (data !== undefined) {
         const { entities } = data?.data
         const therapists: TherapistOption[] = entities.map((entity: any) => {
            const opt: TherapistOption = {
               therapist: {
                  emailAddress: entity.emailAddress,
                  firstName: entity.firstName,
                  lastName: entity.lastName,
                  image: entity.image
               },
               label: getFullName(entity),
               value: entity.firstName + ' | ' + entity.lastName + ' | ' + entity.emailAddress // For autocomplete search
            }

            return opt
         })

         setTherapistOptions(therapists);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data]);

   const toggleHandler = () => {
      setOpenModal(!openModal)

      reset()
   }

   const onSubmit = async (values: any) => {
      const tempValue: string = values?.therapist || ''
      const emailAddress = tempValue.substring(tempValue.lastIndexOf('|') + 2)?.trim()
      setIsLoading(true);
      if (emailAddress && patientId) {
         mutation.mutate({
            therapistEmailAddress: emailAddress,
            patientId: patientId
         });
      }
   }

   const formatOptionLabel = (value: TherapistOption) => {
      return (
         <div key={value.therapist.emailAddress}>
            <LabelUser item={value.therapist} isCoppy={false} noInvite />
         </div>
      )
   }

   return (
      <div ref={rootRef} className="relative">
         <Modal
            show={true}
            size="md"
            popup={true}
            root={rootRef.current ?? undefined}
            onClose={toggleHandler}
            dismissible={true}
         >
            <CustomModalHeader title={t('organizationPatients.setTherapist')} toggle={toggleHandler} />
            <Modal.Body className='modal-body overflow-visible'>
               <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='px-2 pt-4'>
                     <Select
                        errors={errors}
                        formatOptionLabel={formatOptionLabel}
                        hookForm={hookForm}
                        isHolderLabel={true}
                        isMulti={false}
                        name="therapist"
                        onChange={(value: TherapistOption) => { setSelectedTherapist(value) }}
                        options={therapistOptions}
                        placeholder={`${t('therapist')} *`}
                        singleValue={({ children, ...props }: any) => ((
                           /* eslint-disable react/jsx-props-no-spreading */
                           <components.SingleValue {...props}>
                              <div>{props?.data?.label}</div>
                           </components.SingleValue>
                        ))}
                        value={selectedTherapist}
                     />
                  </div>
                  {!isMyPatient && <CTALink className=' px-4 py-2' label={t('organizationPatients.assignToMe')} onClick={() => {
                     mutation.mutate({
                        therapistEmailAddress: userInfo?.emailAddress,
                        patientId: patientId
                     });
                  }} />}
                  <div className="flex justify-center items-center pt-6 px-5 ">
                     <GroupButton
                        className="items-center justify-center pb-2"
                        buttons={[
                           {
                              type: 'button',
                              text: t('modal.cancel'),
                              classType: 'white',
                              action: () => toggleHandler(),
                           },
                           {
                              type: 'submit',
                              text: t('modal.save'),
                              classType: 'blue',
                              isLoading: mutation.isLoading,
                           },
                        ]}
                     />
                  </div>
               </form>
            </Modal.Body>
         </Modal>

         {(isLoading || isFetching) && <SpinnerComponent />}
      </div>
   )
}

export default AssignTherapistModal