import { useEffect, useState } from "react";
import {
    format,
    subMonths,
    addMonths,
    startOfWeek,
    addDays,
    isSameDay,
    lastDayOfWeek,
    getWeek,
    addWeeks,
    subWeeks
} from "date-fns";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import './style.scss'

const Calendar = (props: any) => {
    const { selectedDate, setSelectedDate } = props
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));

    const changeMonthHandle = (btnType: any) => {
        if (btnType === "prev") {
            setCurrentMonth(subMonths(currentMonth, 1));
        }
        if (btnType === "next") {
            setCurrentMonth(addMonths(currentMonth, 1));
        }
    };

    const changeWeekHandle = (btnType: any) => {
        if (btnType === "prev") {
            setCurrentMonth(subWeeks(currentMonth, 1));
            setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
        }
        if (btnType === "next") {
            setCurrentMonth(addWeeks(currentMonth, 1));
            setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
        }
    };

    const onDateClickHandle = (day: any, dayStr: any) => {
        setSelectedDate(day);
    };

    useEffect(() => {
        setCurrentMonth(selectedDate)
    }, [selectedDate])

    const renderCells = () => {
        const startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
        const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 0 });
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        const weekdayFormat = "EEE";

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`mx-1.5 ${isSameDay(day, selectedDate)
                            ? "selected" : isSameDay(day, new Date())
                                ? "today"
                                : ""
                            }`}
                        // key={day}
                        onClick={() => {
                            const dayStr = format(cloneDay, "ccc dd MMM yy");
                            onDateClickHandle(cloneDay, dayStr);
                        }}
                    >

                        <div className="weekDays-selector flex justify-center items-center flex-col">
                            <span className="font-bold h-4.5 leading-4 text-lg">
                                {formattedDate}
                            </span>
                            <span className="text-xs">
                                {format(addDays(startDate, i), weekdayFormat).toUpperCase()}
                            </span>

                        </div>
                    </div>
                );
                day = addDays(day, 1);
            }

            rows.push(
                <div className="row flex date"
                >
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    };

    return (
        <div className="calendar-container">
            <div className=" calendar flex items-center">
                <div className="month font-semibold w-1/3 text-header" style={{ fontSize: '22px' }}>{format(currentMonth, 'MMM, yyyy')}</div>
                <div className="flex items-center ">
                    <div >
                        <HiOutlineChevronLeft className="h-6 w-6 mr-2" onClick={() => changeWeekHandle("prev")} />
                    </div>
                    {renderCells()}
                    <div>
                        <HiOutlineChevronRight className="h-6 w-6 ml-2" onClick={() => changeWeekHandle("next")} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calendar;

