/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { filter, isEmpty, map } from 'lodash';
import GroupButton from 'components/button/groupButton';
import { Label, Modal } from 'flowbite-react';
import Select from 'react-select';
import { pageType, routePaths } from 'utils/constants';
import { getRoles } from 'api/rolesApi';
import makeAnimated from 'react-select/animated';
import classNames from 'classnames';
import { themModal } from 'utils/theme';
import CustomModalHeader from 'components/modal/customModalHeader';
import Svg from 'components/svg';
import './addProgramModal.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import AddProgramByTemplateModal from './addProgramByTemplateModal';
import AddProgramByNewBlankCardModal from './addProgramByNewBlankCardModal';

const AddProgramModal = (props: any) => {
    const { openModal, setOpenModal, headerTitle, type, roles, assignRoles } = props;
    const [t] = useTranslation();
    const [roleSelects, setRoleSelects] = useState([]);
    const [error, setError] = useState(false);
    const location = useLocation();

    const [openModalTemplate, setOpenModalTemplate] = useState(false)
    const [openNewBlankCardModal, setOpenNewBlankCardModal] = useState(false)
    const navigate = useNavigate()
    const rootRef = useRef(null);
    const roleIds = map(roles, 'id');
    const { data: roleOptions }: any = useQuery(['getRoles', type], () => getRoles({ type, limit: 0 }), { staleTime: Infinity });
    const roleSelectOptions: any = filter(roleOptions?.data?.entities, (r: any) => !roleIds.includes(r?.id)).map((r: any) => ({
        value: r?.id,
        label: r?.displayName,
    }));

    const handleChange = (options: any) => {
        setRoleSelects(options);
        setError(isEmpty(options));
    };

    const handleClose = () => {
        setOpenModal(false);
        setRoleSelects([]);
        setError(false);
    };
    const handleSave = () => {
        setError(isEmpty(roleSelects));
        if (!isEmpty(roleSelects)) {
            assignRoles(roleSelects);
            handleClose();
        }
    };

    const handleAddNewBlankrProgram = () => {
       setOpenNewBlankCardModal(true)
    }

    const handleAddProgramByTemplate = () => {
        setOpenModalTemplate(true)
    }
    return (
        <div ref={rootRef}>
            <Modal
                className="modal-add-program"
                show={true}
                size="md"
                root={rootRef.current ?? undefined}
                onClose={() => handleClose()}
                dismissible={true}
                theme={themModal}

            >
                <CustomModalHeader title={headerTitle} toggle={() => handleClose()} />
                <Modal.Body theme={{ base: 'flex-1 pb-6 pt-3 px-12' }}>
                    <div className='flex justify-between px-2'>
                        <div className='flex flex-col'>
                            <div className='border flex items-center justify-center program-type' onClick={handleAddProgramByTemplate}>
                                <div className='program'>
                                    <Svg name='program' className='w-auto' />

                                </div>
                                <div className='program-white'>
                                    <Svg name='program-white' className='w-auto' />
                                </div>

                            </div>
                            <div className='flex justify-center mt-2 text-tertiary text-sm font-normal'>
                                {t('useATemplate')}
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className='border flex items-center justify-center program-type' onClick={handleAddNewBlankrProgram}>
                                <div className='program'>
                                    <Svg name='blank-program' className='w-auto' />
                                </div>
                                <div className='program-white'>
                                    <Svg name='blank-program-white' className='w-auto' />
                                </div>
                            </div>
                            <div className='flex justify-center mt-2 text-tertiary text-sm font-normal'>
                                {t('newBlankProgram')}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {openModalTemplate && (
                    <AddProgramByTemplateModal
                        headerTitle={t('addProgramByTemplate')}
                        openModalTemplate={openModalTemplate}
                        setOpenModalTemplate={setOpenModalTemplate}
                        setOpenModal={setOpenModal}
                    />
                )}
                {openNewBlankCardModal && (
                    <AddProgramByNewBlankCardModal
                    headerTitle={t('newBlankProgram')}
                        openBlankCardModal={openNewBlankCardModal}
                        setOpenBlankCardModal={setOpenNewBlankCardModal}
                        setOpenModal={setOpenModal}
                    />
                )}
            </Modal>


        </div>
    );
};
export default AddProgramModal;
