import { useTranslation } from 'react-i18next'
import './exerciseItemPdf.scss'
import { getYoutubeId, isImageType, isVideoType, toHHMMSS } from 'utils/utils';
import Svg from 'components/svg';

const ExerciseItemPdf = (props: any) => {
    const { exercise } = props
    const [t] = useTranslation()

    return (
        <div className="exercise-pdf-item rounded-md ">
            <div className=' flex bg-lightTertiary exercise-header rounded-t-md p-4 flex-col'>
                <div className='text-sm font-medium  text-secondary'>{exercise.name}</div>
                <div className='flex gap-1'>
                    {!isNaN(parseInt(exercise?.sets)) && parseInt(exercise?.sets) > 0 && <div className='p-1 bg-white border rounded-md text-10 text-tertiary'>{exercise?.sets || 0} {t('Sets')}</div>}
                    {!isNaN(parseInt(exercise?.reps)) && parseInt(exercise?.reps) > 0 && <div className='p-1 bg-white border rounded-md text-10 text-tertiary'>{exercise?.reps || 0} {t('Reps')}</div>}
                    {!isNaN(parseInt(exercise?.duration)) && parseInt(exercise?.duration) > 0 && <div className='p-1 bg-white border rounded-md text-10 text-tertiary'>{t('Duration')}: {toHHMMSS(exercise?.duration) || 0} </div>}
                    <div className='p-1 bg-white border rounded-md text-10 text-tertiary'>{t('Times per Day')}: {exercise?.timesPerDay || 0} </div>
                    {exercise.isMonday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>MO </div>}
                    {exercise.isTuesday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>TU </div>}
                    {exercise.isWednesday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>WE </div>}
                    {exercise.isThursday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>TH </div>}
                    {exercise.isFriday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>FR </div>}
                    {exercise.isSaturday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>SA </div>}
                    {exercise.isSunday && <div className='p-1 bg-tertiary border rounded-md text-10 text-white font-semibold'>SU </div>}
                </div>
            </div>
            <div className='rounded-b-md border-b exercise-content'>
                <div className='p-4 flex justify-center '>
                    {exercise?.videoUrl && !exercise?.file?.url && (
                        <div className='w-2/3 relative aspect-video'>
                            <span className="absolute left-0 right-0 top-0 bottom-10 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                <Svg name='youtube-play-icon' className='w-auto' />
                            </span>
                            <div className='w-auto'>
                                <img className="preview w-full h-full object-cover rounded" src={`https://img.youtube.com/vi/${getYoutubeId(exercise?.videoUrl)}/hqdefault.jpg`} alt="avatar" />
                            </div>
                            <div className="w-full flex items-center justify-center">
                                <a href={exercise?.videoUrl} rel="noreferrer" className="pt-4 text-tertiary text-center underline" target="_blank">{exercise?.videoUrl}</a>
                            </div>
                        </div>

                    )
                    }
                    {!exercise?.videoUrl && exercise?.file?.url && isVideoType(exercise?.file?.type) && (
                        <div className='w-2/3 relative aspect-video'>
                            <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                                <Svg name='play-icon' className='w-[60px] h-[60px]' />
                            </span>
                            <div className='w-full bg-black aspect-video flex justify-center'>
                                <video className="preview w-auto h-full object-cover rounded" src={exercise?.file?.url} />
                            </div>
                        </div>
                    )}
                    {!exercise?.videoUrl && exercise?.file?.url && isImageType(exercise?.file?.type) && (
                        <div className='w-2/3 aspect-video'>
                            <img className="preview w-full h-full object-cover rounded" src={exercise?.file?.url} alt="avatar" />
                        </div>)}
                </div>
                <div className='pb-4 pl-4 pr-4 whitespace-pre-line'>
                    {exercise?.description}
                </div>
            </div>

        </div>

    )
}
export default ExerciseItemPdf