import { useEffect, useState } from 'react';
import './scrollbar.scss';

const initAny: any = null;

const Scrollbar = (props: any) => {
  const { children, onScroll, handleEndScroll, className, endScroll, name } = props;
  const [scroll, setScroll] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [myTimeout, setMyTimeout] = useState(initAny);
  const [distanceY, setDistanceY] = useState(initAny);
  const [checkScroll, setCheckScroll] = useState(false);
  const keyRandom = `scrollbar-${name || (Math.random() + 1).toString(36).substring(7)}`;

  const handerScroll = () => {
    if (myTimeout) clearTimeout(myTimeout);
    setIsScroll(true);
    setMyTimeout(
      setTimeout(() => {
        setIsScroll(false);
      }, 1000),
    );
  };

  const handerMouseUp = () => {
    document.removeEventListener('mousemove', handerClickScroll, true);
  };

  const handerMouseDown = () => {
    document.addEventListener('mousemove', handerClickScroll, true);
    document.addEventListener('mouseup', handerMouseUp, true);
    return () => {
      document.removeEventListener('mouseup', handerMouseUp, true);
    };
  };

  const handerClickScroll = (event: any) => {
    const layerY = event.screenY - distanceY;
    const docScrollbar: any = document.getElementById(keyRandom);
    const scrollDivContent: any = docScrollbar.getElementsByClassName('scrollbar-content');
    const { scrollHeight, offsetHeight } = scrollDivContent[0];
    const heightScroll = offsetHeight - 100;
    const newLayerY = layerY - 50 < 0 ? 0 : layerY - 50 > heightScroll ? heightScroll : layerY - 50;
    const percentPoint = newLayerY / heightScroll;
    const fixScrollHeight = scrollHeight - offsetHeight;
    if (endScroll) scrollDivContent[0].scrollTop = -(fixScrollHeight - percentPoint * fixScrollHeight);
    if (!endScroll) scrollDivContent[0].scrollTop = percentPoint * fixScrollHeight;
  };

  const handleOnScroll = (event: any) => {
    const { scrollHeight, offsetHeight, scrollTop } = event.target;
    setScroll((scrollTop / (scrollHeight - offsetHeight)) * (offsetHeight - 100));
    handerScroll();
    if (onScroll) onScroll({ scrollHeight, offsetHeight, scrollTop });
    if (handleEndScroll && !endScroll && scrollTop >= scrollHeight - offsetHeight) handleEndScroll();
    if (handleEndScroll && endScroll && scrollTop - 0.5 <= -(scrollHeight - offsetHeight)) handleEndScroll();
  };

  const handerMouseOver = (event: any) => {
    if (distanceY === null) setDistanceY(event.screenY - event.nativeEvent.layerY);
    if (event.target.offsetParent.id === keyRandom) {
      const scrollDivContent: any = event.target.offsetParent.getElementsByClassName('scrollbar-content');
      const scrollDivContentScroll: any = event.target.offsetParent.getElementsByClassName('scrollbar-content-scroll');
      setCheckScroll(scrollDivContentScroll[0].offsetHeight > scrollDivContent[0].offsetHeight);
    }
  };

  return (
    <div id={keyRandom} className={`relative scrollbar-custom h-full`} onMouseOver={handerMouseOver}>
      <div
        onClick={handerClickScroll}
        className={`transition-opacity absolute w-2 right-0.5 inset-y-0.5 rounded  ${endScroll ? 'flex flex-col-reverse' : ''}
          ${checkScroll ? 'z-10 hover:opacity-1' : 'hidden'} ${isScroll ? 'opacity-1' : 'opacity-0'}`}
      >
        <button
          className="relative block w-full cursor-pointer h-[100px] z-10 rounded"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', transform: 'translateY(' + scroll + 'px)' }}
          onMouseDown={handerMouseDown}
        />
      </div>
      <div className={`overflow-auto scrollbar-content scroll-auto  ${className || ''} ${endScroll ? 'flex flex-col-reverse' : ''}`} onScroll={handleOnScroll}>
        <div className="scrollbar-content-scroll">{children}</div>
      </div>
    </div>
  );
};
export default Scrollbar;
