/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { isEmpty, map, omit } from 'lodash';
import GroupButton from 'components/button/groupButton';
import { Modal } from 'flowbite-react';
import { themModal } from 'utils/theme';
import CustomModalHeader from 'components/modal/customModalHeader';
import './addProgramModal.scss'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'components/select';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';
import { getProgramTemplates } from 'api/programTemplateApi';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { messageErrors } from 'utils/utils';
import { createProgram } from "api/programApi";

const AddProgramByTemplateModal = (props: any) => {
    const { openModalTemplate, setOpenModalTemplate, headerTitle, type, roles, assignRoles, setOpenModal } = props;
    const [t] = useTranslation();
    const [programTemplateOptions, setProgramTemplateOptions] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const rootRef = useRef(null);
    const { userInfo } = useSelector(userSelector);
    const queryClient = useQueryClient();
    const { data: programTemplates }: any = useQuery(['getProgramTemplates', userInfo.id], () => getProgramTemplates({ page: 0, limit: 0 }), { staleTime: Infinity });
    const params = useParams()

    const ValidateSchema = Yup.object().shape({
        programTemplateId: Yup.string().required(t('userManagementPage.requiredField')),
    });

    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema),

    });

    const {
        formState: { errors },
        handleSubmit,
    } = hookForm

    const handleClose = () => {
        setOpenModalTemplate(false);
    };

    useEffect(() => {
        if (programTemplates) {
            const tempOptions = programTemplates.data.entities.map((item: any) => ({ label: item.name, value: item.id }))
            setProgramTemplateOptions(tempOptions)

        }
    }, [programTemplates])

    const handleSelectTemplate = (option: any) => {
        hookForm.clearErrors('programTemplateId')
        const template: any = [option]
        setSelectedTemplate(template)
        hookForm.setValue('programTemplateId', template[0]?.value)
    }
    const handleMutation = (payload: any) => {
        return createProgram(payload)
    }

    const mutationCreateProgram = useMutation('createProgram', {
        mutationFn: handleMutation,
        onSuccess: () => {
            const message: string = t('createdProgram');
            toast.success(message);
            queryClient.invalidateQueries('getPatientPrograms', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getProgramById', { refetchActive: true }, { cancelRefetch: true });
            queryClient.invalidateQueries('getAllPatientPrograms', { refetchActive: true }, { cancelRefetch: true });
            setOpenModalTemplate(false)
            setOpenModal(false)
        },
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const onSubmit = (value: any) => {
        const template = programTemplates.data.entities.find((item: any) => item.id === value.programTemplateId)
        const payload = {
            ...omit(template, ['id', 'userId', 'exercises', 'created', 'deleted', 'modified']),
            exercises: template.exercises.map((item: any) => ({ ...omit(item, ['id', 'programTemplateId', 'created', 'deleted', 'modified', 'file']) })),
            status: 'Active',
            patientId: params.id
        }
        mutationCreateProgram.mutate(payload)
    };

    return (
        <div ref={rootRef}>
            <Modal
                className="modal-add-program-template"
                show={openModalTemplate}
                size="md"
                root={rootRef.current ?? undefined}
                onClose={() => handleClose()}
                dismissible={true}
                theme={themModal}

            >
                <CustomModalHeader title={headerTitle} toggle={() => handleClose()} />
                <Modal.Body theme={{ base: 'flex-1 pb-6 pt-3 px-12' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <Select
                                options={programTemplateOptions}
                                onChange={handleSelectTemplate}
                                placeholder={headerTitle}
                                value={selectedTemplate}
                                errors={errors}
                                name="programTemplateId"
                                isMulti={false}
                                isHolderLabel={false}
                            />
                        </div>
                        <GroupButton
                            className="items-center justify-center pt-5 pb-2"
                            buttons={[
                                {
                                    type: 'button',
                                    text: t('modal.cancel'),
                                    classType: 'white',
                                    action: () => handleClose(),
                                },
                                {
                                    type: 'submit',
                                    text: t('modal.continue'),
                                    classType: 'blue',
                                    isLoading: false,
                                },
                            ]}
                        />
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default AddProgramByTemplateModal;
