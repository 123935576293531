import './style.scss';
import {
  HiOutlineClipboard,
  HiOutlineMail,
  HiOutlineCalendar,
  HiOutlinePencil,
  HiOutlineBadgeCheck,
  HiOutlineCake,
  HiOutlineUserCircle
} from 'react-icons/hi';
import { pageType, roleName, routePaths } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/button/button';
import moment from 'moment';
import { isArray, map, pick } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import useUser from 'hooks/useUser';
import InputText from 'components/inputText';
import { Badge } from 'flowbite-react';
import Select from 'components/select';
import { handleTrimString } from 'utils/utils';
import DatePicker from 'components/datePicker';
import { assignTherapist, updateOrganizationUser } from 'api/userApi';
import Avatar from 'components/GroupAvatar/Avatar';
import LabelUser from 'components/labelUser';
import { components } from 'react-select';
import SpinnerComponent from 'components/spinner';

const Card: any = ({ children, className = '' }: any) => <div className={`min-h-[48px] card max-h-[48px] mb-4 md:flex ${className}`}>{children}</div>;
const CardTitle: any = ({ children }: any) => <div className="card-title w-40 h-5 text-tertiary text-outline flex items-center mt-3.5">{children}</div>;
const CardContent: any = ({ children }: any) => (
  <div className="text-on-primary-container card-content w-full flex flex-1 max-w-[500px] pl-7 md:ml-0">{children}</div>
);

const DetailTab = (props: any) => {
  const { type, targetData, isWrite, roleData, messageUpdate, therapistOptions, selectedTherapist, setSelectedTherapist, isPatientDetailSite = false, isTherapistDetailSite = false } = props;

  const [dateValue, setDateValue] = useState('');
  const queryClient = useQueryClient();
  const params: any = useParams();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const { fetchCurrentUser } = useUser();
  const [roles, setRoles] = useState([]);
  const [t] = useTranslation();
  const [isEdit, setIsEdit] = useState(false)
  const [disableSetTherapist, setDisableSetTherapist] = useState(false)
  const rootRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate()
  const getRoleOption = (role: any): any => ({ value: role?.id, label: role?.displayName });
  const [isLoading, setIsLoading] = useState(false)
  const getRolesInData = (user: any) => {
    const siteRoles = user?.roles;
    const orgRoles = map(
      user?.organizations?.filter((o: any) => o.id === organizationId && !!o.role),
      'role',
    );
    return type === pageType.SITE ? siteRoles : orgRoles;
  };

  const ValidateSchemaFullName = Yup.object().shape({
    firstName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    lastName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
  });

  const hookForm = useForm({ mode: 'onChange', resolver: yupResolver(ValidateSchemaFullName) });

  const {
    formState: { errors },
    handleSubmit,
    clearErrors,
    setValue,
    reset
  } = hookForm

  const handleSuccess = (message: string) => {
    if (messageUpdate) {
      toast.success(messageUpdate);
    } else toast.success(message);
    if (params?.id === userInfo.id) fetchCurrentUser();
  };

  const handleSubmitUser = (data: any) => {
    return updateOrganizationUser(data)
  };

  const muUpdateUser = useMutation('update-usesOrg', {
    mutationFn: handleSubmitUser,
    onSuccess: () => {
      setIsEdit(false)
      queryClient.invalidateQueries('getOrganizationTherapists', { refetchActive: true }, { cancelRefetch: true });
      queryClient.invalidateQueries('getPatientProfile', { refetchActive: true }, { cancelRefetch: true });
      queryClient.invalidateQueries('getPatients', { refetchActive: true }, { cancelRefetch: true });
      queryClient.invalidateQueries('getPatient', { refetchActive: true }, { cancelRefetch: true });
      queryClient.invalidateQueries('getTherapist', { refetchActive: true }, { cancelRefetch: true });
      handleSuccess(t('userManagementPage.editSuccessMessage'));
      setIsLoading(false)
    },
    onError: async (error: any) => {
      const message: string = `${error.response.data.errors?.[0].detail}`;
      toast.error(message);
    },
  });

  const onSubmit = async (payload: any) => {
    setIsLoading(true)
    const role = roleData?.find((role: any) => role.id === payload.roleIds)?.displayName
    if (payload.roleIds && !isArray(payload.roleIds)) payload.roleIds = [payload.roleIds];
    handleTrimString(payload)
    await muUpdateUser.mutate({ payload, id: params?.id });
    if (payload.therapist && role !== roleName.THERAPIST) {
      await assignTherapist({
        therapistEmailAddress: selectedTherapist?.therapist?.emailAddress,
        patientId: params?.id
      }
      )
    }

    if (role === roleName.THERAPIST && isPatientDetailSite) {
      navigate(`${routePaths.ORG_THERAPISTS}/${params?.id}`);
    }
    if (role === roleName.PATIENT && isTherapistDetailSite) {
      navigate(`${routePaths.ORG_PATIENTS}/${params?.id}`);
    }

  };

  useEffect(() => {
    if (targetData) {
      const roles = getRolesInData(targetData);
      setRoles(roles);
      if (targetData.dateOfBirth) setDateValue(moment(targetData.dateOfBirth).format('MMMM DD, YYYY'));
      const selectRoles = pageType.SITE ? map(roles, 'id') : roles?.[0]?.id
      reset(pick(targetData, ['firstName', 'lastName', 'emailAddress']));
      hookForm.setValue('roleIds', selectRoles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetData, isEdit]);

  const handleSelectDate = (date: Date) => {
    const formatDate: any = moment(date).format('YYYY-MM-DD');
    setValue('dateOfBirth', formatDate);
    const tempDate: any = moment(date).format('MMMM DD, YYYY');
    setDateValue(tempDate);
  };

  const handleCloseEdit = () => {
    setIsEdit(false)
    clearErrors()
  }

  const renderTherapist = (item: any) => {
    const org = item?.organizations?.find((org: any) => org.id === organizationId);
    const orgName = `${org?.therapist?.firstName} ${org?.therapist?.lastName}`;
    return (
      org?.therapist && (
        <div className="flex items-center">
          <Avatar item={org?.therapist} />
          <div className='flex flex-col'>
            <p className="ml-2 break-word text-sm dark:text-white font-semibold flex items-center">{orgName}</p>
            <p className="ml-2 break-all text-xs">{org?.therapist?.emailAddress}</p>
          </div>
        </div>
      )
    );
  };

  const formatOptionLabel = (value: any) => {
    return (
      <div key={value.therapist.emailAddress}>
        <LabelUser item={value.therapist} isCoppy={false} noInvite />
      </div>
    )
  }

  useEffect(() => {
    const role = roleData?.find((role: any) => role.id === hookForm.watch("roleIds"))?.displayName
    if (role === roleName.THERAPIST) {
      setDisableSetTherapist(true)
    } else {
      setDisableSetTherapist(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hookForm.watch("roleIds")])

  const ActionEdit: any = ({ change, className = '' }: any) => (
    <Button onClick={change} className={`h-8 w-8 ${className} flex justify-center`} color="gray-100">
      <HiOutlinePencil className="w-4 h-4 text-black" />
    </Button>
  );

  console.log("isLoading", isLoading);


  return (
    <>
      {!isLoading ? (<div className="detail-tab md:p-8 p-4 border border-gray-200 rounded md:mx-6 md:my-2 mx-4 justify-between relative">
        {!isEdit &&
          <div className='absolute right-0 top-0'>
            <ActionEdit change={() => setIsEdit(!isEdit)} />
          </div>
        }
        <form onSubmit={handleSubmit(onSubmit)} >
          <div className="xl:flex md:block justify-between flex-1 mt-1 md:mt-0">
            <div className="lg:mr-8">
              <Card>
                <CardTitle>
                  <HiOutlineClipboard className="w-5 h-5 mr-2" />
                  {t('userManagementPage.fullName')}
                </CardTitle>
                <CardContent>
                  {!isEdit && <p className="mt-3 mr-2 font-semibold">{`${targetData?.firstName} ${targetData?.lastName}`}</p>}
                  {isEdit && (
                    <div className='edit-field flex w-full'>
                      <InputText name="firstName" className="mr-2" hookForm={hookForm} isHolderLabel={false} />
                      <InputText name="lastName" className="mr-2" hookForm={hookForm} isHolderLabel={false} />
                    </div>
                  )}
                </CardContent>
              </Card>
              <Card>
                <CardTitle>
                  <HiOutlineMail className="w-5 h-5 mr-2" />
                  {t('userManagementPage.email')}
                </CardTitle>
                <CardContent>
                  {!isEdit && (<p className="mt-3 mr-2 break-all">{`${targetData?.emailAddress}`}</p>)}
                  {isEdit && (
                    <div className='edit-field flex w-full'>
                      <InputText name="emailAddress" className="mr-2" hookForm={hookForm} isHolderLabel={false} />
                    </div>
                  )}
                </CardContent>
              </Card>
              <Card className="last:p-0">
                <CardTitle >
                  <HiOutlineCake className="w-5 h-5 mr-2" />
                  {t('userManagementPage.dateOfBirth')}
                </CardTitle>
                <CardContent>
                  {!isEdit && <p className="mt-3 mr-2">{targetData?.dateOfBirth ? moment(targetData?.dateOfBirth).format('MMMM DD, YYYY') : ''}</p>}
                  {isEdit && (
                    <div className='edit-field flex w-full'>
                      <DatePicker
                        className="mr-2 mt-1 md:mt-0"
                        hookForm={hookForm}
                        name="dateOfBirth"
                        dateValue={dateValue}
                        handleSelectDate={handleSelectDate}
                        isHolderLabel={true}
                      />
                    </div>
                  )}
                </CardContent>
              </Card>
              {roleData && (
                <Card className="last:p-0">
                  <CardTitle>
                    <HiOutlineBadgeCheck className="w-5 h-5 mr-2" />
                    {t('userManagementPage.role')}
                  </CardTitle>
                  <CardContent>
                    {!isEdit && (
                      <div className="role flex flex-wrap mt-2">
                        {roles.map((role: any) => (
                          <Badge size="sm" color="gray" className="font-normal py-1 mr-2.5 my-1 text-on-primary-container bg-gray-100" key={role.id}>
                            {role?.displayName}
                          </Badge>
                        ))}
                      </div>
                    )}
                    {isEdit && (
                      <div className='edit-field flex w-full'>
                        <Select
                          options={map(roleData, role => getRoleOption(role))}
                          hookForm={hookForm}
                          name="roleIds"
                          isMulti={type === pageType.SITE}
                          className="mr-2 mt-1 md:mt-0"
                        />
                      </div>
                    )}
                  </CardContent>
                </Card>
              )}
            </div>
            <div>
              <Card>
                <CardTitle>
                  <HiOutlineCalendar className="w-5 h-5 mr-2" />
                  {t('userManagementPage.createdAt')}
                </CardTitle>
                <CardContent>
                  <p className="mt-3 mr-2 min-w-[250px]">{targetData.created ? moment(targetData.created).format('MMMM D, YYYY, h:mm:ss A') : ''}</p>
                </CardContent>
              </Card>
              <Card>
                <CardTitle>
                  <HiOutlineCalendar className="w-5 h-5 mr-2" />
                  {t('userManagementPage.lastLogin')}
                </CardTitle>
                <CardContent>
                  <p className="mt-3 mr-2 min-w-[250px]">{targetData.lastLogin ? moment(targetData?.lastLogin).format('MMMM D, YYYY, h:mm:ss A') : ''}</p>
                </CardContent>
              </Card>
              {therapistOptions && !disableSetTherapist && (
                <Card>
                  <CardTitle>
                    <HiOutlineUserCircle className="w-5 h-5 mr-2" />
                    {t('Set Therapist')}
                  </CardTitle>
                  <CardContent>
                    {!isEdit && (
                      renderTherapist(targetData)
                    )}
                    {isEdit && (
                      <div className='edit-field flex w-full'>
                        <Select
                          errors={errors}
                          formatOptionLabel={formatOptionLabel}
                          hookForm={hookForm}
                          isHolderLabel={true}
                          isMulti={false}
                          name="therapist"
                          onChange={(value: any) => { setSelectedTherapist(value) }}
                          options={therapistOptions}
                          singleValue={({ children, ...props }: any) => ((
                            /* eslint-disable react/jsx-props-no-spreading */
                            <components.SingleValue {...props}>
                              <div>{props?.data?.label}</div>
                            </components.SingleValue>
                          ))}
                          value={selectedTherapist}
                        />
                      </div>
                    )}
                  </CardContent>
                </Card>
              )}

            </div>
          </div>
          {
            isEdit &&
            <div className="flex mt-5">
              <button
                type="submit"
                className={`mr-5 h-34 flex items-center justify-center text-white bg-primary border border-primary focus:outline-none font-medium text-sm px-5 py-2.5 text-center`}
              >
                {t('saveChanges')}
              </button>
              <button
                type='button'
                onClick={() => handleCloseEdit()}
                className={`text-primary flex h-34 items-center justify-center hover:bg-blue-100 border border-primary focus:outline-none font-medium text-sm px-5 py-2.5 text-center`}
              >
                {t('cancel')}
              </button>
            </div>
          }
        </form>
      </div >) : (
        <SpinnerComponent />

      )}
    </>

  );
};
export default DetailTab;
