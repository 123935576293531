import dayjs from 'dayjs';
import { Slide, toast, TypeOptions } from 'react-toastify';
import { concat, find, flattenDeep, intersection, isArray, isEmpty, map, uniq } from 'lodash';
import * as XLSX from 'xlsx';
import { codeVerifier, ORG_COOKIE_KEY, pageType, permissionKeys, routePaths } from './constants';
import { getCookie } from './cookie';
import { userType } from './proptypes';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import error = toast.error;


const formatDate = (date: string) => {
  const datejs = dayjs(date);
  const $d = datejs.toDate();
  const $D = datejs.date();
  const $M = datejs.month();
  const $y = datejs.year();

  const dateTime = $d.toString().split(' ')[4];

  const splitTime = dateTime.split(':');

  return `${$D > 9 ? $D : '0' + $D}/${$M + 1 > 9 ? $M + 1 : '0' + ($M + 1)}/${$y} ${
    parseInt(splitTime[0]) > 12 ? parseInt(splitTime[0]) - 12 : splitTime[0]
  }:${splitTime[1]}${parseInt(splitTime[0]) > 12 ? 'pm' : 'am'}`;
};

const checkLowerUpper = (value: string) => {
  return /(?=.*[A-Z])(?=.*[a-z]).*$/.test(value);
};

const checkNumberDymbol = (value: string) => {
  return /(?=.*\d)(?=.*\W+).*$/.test(value);
};

const checkLeast8Char = (value: string) => {
  return value.length >= 8;
};

const checkNoSpaces = (value: string) => {
  return /^\S+$/.test(value);
};

const validatiePassword = (value: string) => {
  return checkLowerUpper(value) && checkNumberDymbol(value) && checkLeast8Char(value) && checkNoSpaces(value);
};

const showToast = (key: TypeOptions, message: any) => {
  if (key === 'success' || key === 'error' || key === 'warning') {
    return toast[key](message, { transition: Slide, autoClose: 1500, icon: false });
  }
};

const messageErrors = (error: any, translation: any) => {
  if ([500, 404].includes(error?.response?.status)) {
    return translation('generalErrors');
  } else {
    const message = error?.response?.data?.errors[0]?.detail;
    return (isArray(message) ? translation(message[0]) : translation(message)) || translation('generalErrors');
  }
};

const exportToFile = (exportedData: any, fileName: string) => {
  const wb = XLSX.utils.json_to_sheet(exportedData);
  const wbout = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wbout, wb);
  XLSX.writeFile(wbout, fileName);
};

const checkPermission = (user: any, type: any, permNeed: any = [], organizationId = getCookie(ORG_COOKIE_KEY), exception?: any): any => {
  const permissionsSite = uniq(flattenDeep(map(user?.roles, (r: any) => r?.permissions)));
  const permissionsOrg = find(user?.organizations, o => o.id === organizationId)?.role?.permissions || [];

  if (permissionsSite.includes(permissionKeys.PLATFORM_ADMIN)) return true;
  if (exception) return false;
  let permOfUser = concat(permissionsSite, permissionsOrg);
  if (type === pageType.ORGANIZATION) permOfUser = permissionsOrg;
  if (type === pageType.SITE) permOfUser = permissionsSite;
  return permOfUser && (isEmpty(permNeed) || !isEmpty(intersection(permOfUser, permNeed)));
};

const checkRole = (user: any, roleNames: any[], organizationId = getCookie(ORG_COOKIE_KEY)): any => {
  const displayName = find(user?.organizations, o => o.id === organizationId)?.role?.displayName || [];
  return roleNames.includes(displayName);
};

const getNavbarUrl = (user: any, type: string, organizationId = getCookie(ORG_COOKIE_KEY)) => {
  const permissionsSite = uniq(flattenDeep(map(user?.roles, (r: any) => r?.permissions)));
  const { READ_USER, WRITE_USER, PLATFORM_ADMIN, READ_ROLE, WRITE_ROLE, READ_ORG, WRITE_ORG, READ_AUTHEN, WRITE_AUTHEN } = permissionKeys;
  if (type === pageType.SITE) {
    if (!isEmpty(intersection(permissionsSite, [READ_USER, WRITE_USER, PLATFORM_ADMIN]))) return routePaths.ADMIN_USERS_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_ROLE, WRITE_ROLE]))) return routePaths.ROLES_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_ORG, WRITE_ORG]))) return routePaths.ORGANIZATION_PAGE;
    if (!isEmpty(intersection(permissionsSite, [READ_AUTHEN, WRITE_AUTHEN]))) return routePaths.MY_TOKENS;
  } else {
    const permissionsOrg = find(user?.organizations, o => o.id === organizationId)?.role?.permissions || [];
    if (!isEmpty(intersection(permissionsSite, [PLATFORM_ADMIN]))) return routePaths.ORG_OVERVIEW_PAGE;
    if (!isEmpty(intersection(permissionsOrg, [READ_USER, WRITE_USER]))) return routePaths.ORG_OVERVIEW_PAGE;
  }
  return routePaths.DASHBOARD_PAGE;
};

const getAvatarColor = (item: userType) => {
  const colors = ['#FACA15', '#31C48D', '#16BDCA', '#6875F5', '#9061F9', '#E74694', '#FF5A1F', '#F05252'];
  const sumOfDateNumbers = moment(item?.created)
    .utc()
    .format('MM DD YYYY hh mm ss')
    .split('')
    .map(Number)
    .reduce((a, b) => {
      return a + b;
    }, 0);
  const pick = sumOfDateNumbers % colors.length;
  return colors[pick];
};

const copyToClipboard = (value: string, notification: string) => {
  copy(value);
  toast.success(notification);
};

const handleTrimString = (values: any) => {
  Object.keys(values).forEach(k => {
    if (typeof values[k] === 'string') {
      values[k] = values[k].trim();
    }
  });
  return values;
};

const base64UrlEncode = (buffer: any) => {
  return btoa(String.fromCharCode.apply(null, buffer)).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

const sha256 = async (buffer: any) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(buffer);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  return new Uint8Array(hashBuffer);
};

const generateCodeVerifier = () => {
  const codeVerifier = new Uint8Array(32);
  window.crypto.getRandomValues(codeVerifier);
  return base64UrlEncode(codeVerifier);
};

const generateCodeChallenge = async (codeVerifier: any) => {
  const hashedCodeVerifier = await sha256(codeVerifier);
  return base64UrlEncode(hashedCodeVerifier);
};

const loginPage = async (prompt: string = 'login') => {
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  const redirectUri = window.location.origin;
  const domain = process.env.REACT_APP_OAUTH_DOMAIN || '';
  const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID || '';
  const audience = process.env.REACT_APP_OAUTH_AUDIENCE || '';
  const authorizationUrl = `${domain}/oauth2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&code_challenge=${codeChallenge}&code_challenge_method=S256&state=12345678&audience=${audience}&scope=openid profile email offline_access&prompt=${prompt}`;
  window.location.href = authorizationUrl;
};
const getYoutubeId = (url: string) => {
  // Regular expression to extract the YouTube video ID
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);

  if (match && match[7].length === 11) {
    return match[7];
  } else {
    return null;
  }
};
const isVideoType = (type: string) => {
  return /^video\//.test(type);
};

const isImageType = (type: string) => {
  return /^image\//.test(type);
};

const getSecondsFromHHMMSS = (value: any) => {
  const [str1, str2, str3] = value.split(':');

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
};

const toHHMMSS = (secs: any) => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map(val => (val < 10 ? `0${val}` : val))
    .filter((val, index) => val !== '00' || index > 0)
    .join(':');
  // .replace(/^0/, "");
};

const isSafari = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('safari');
};

const compressVideo = async (file: any) => {
  try {
    const inputFile = file?.name;
    const outputFile = 'video.mp4';
    const ffmpeg = new FFmpeg();
    await ffmpeg.load();
    await ffmpeg.writeFile(inputFile, await fetchFile(file));
    await ffmpeg.exec(['-i', inputFile,'-vf','scale=-2:360','-c:v', 'libx264','-crf', '18', outputFile]);
    const fileData = await ffmpeg.readFile(outputFile);
    const data = new Uint8Array(fileData as ArrayBuffer);
    const blob = new Blob([data.buffer], { type: 'video/mp4' });
    return new File([blob], outputFile, { type: 'video/mp4' });
  }catch(error) {
   console.log('error', error);
    return file;
  }

};

export {
  formatDate,
  showToast,
  checkLowerUpper,
  checkNumberDymbol,
  checkLeast8Char,
  checkNoSpaces,
  validatiePassword,
  messageErrors,
  exportToFile,
  checkPermission,
  checkRole,
  getNavbarUrl,
  getAvatarColor,
  copyToClipboard,
  handleTrimString,
  generateCodeVerifier,
  generateCodeChallenge,
  loginPage,
  getYoutubeId,
  isVideoType,
  isImageType,
  getSecondsFromHHMMSS,
  toHHMMSS,
  isSafari,
  compressVideo,
};
