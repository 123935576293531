import { useTranslation } from 'react-i18next';
const searchLogoPath = require('../../assets/image/svg/search-normal.svg').default || '';
const NoResult = () => {
  const [t] = useTranslation();
  return (
    <div className="bg-white border border-gray-200 rounded-sm flex flex-col items-center p-8">
      <div className="flex flex-col items-center">
        <img src={searchLogoPath} alt="logo" />
        <div className="text-black text-3xl font-semibold mt-6 text-header">{t('searchResult.title')}</div>
        <div className="text-gray-400 text-lg font-normal mt-2">{t('searchResult.subTitle')}</div>
      </div>
    </div>
  );
};
export default NoResult;
