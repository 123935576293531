/* eslint-disable react/jsx-props-no-spreading */
import { Modal } from 'flowbite-react';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'styles/styles.scss';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import './confirmDeleteModal.scss'

const ConfirmDeactivateModal = (props: any) => {
    const { openModal, setOpenModal, handleAction, selectedUser } = props
    const rootRef = useRef<HTMLDivElement>(null);
    const [t] = useTranslation()

    const closeModal = () => {
        setOpenModal(false)
    };

    return (
        <div ref={rootRef} >
            <Modal
                show={openModal}
                size="lg"
                root={rootRef.current ?? undefined}
                onClose={closeModal}
                dismissible={true}
                className='modal-confirm-delete'
            >
                <CustomModalHeader
                    title={selectedUser?.isActive ? t('Deactivate Account') : t('Activate Account')}
                    toggle={closeModal}
                />
                <Modal.Body className='modal-body'>
                    <div className='text-center mb-5'>{selectedUser?.isActive ? t('Are you sure you want to Deactivate Account?'): t('Are you sure you want to Activate Account?')}</div>
                    <GroupButton
                        className="items-center justify-center pt-1 pb-2"
                        buttons={[
                            {
                                type: 'button',
                                text: t('modal.cancel'),
                                classType: 'white',
                                action: () => closeModal(),
                            },
                            {
                                type: 'button',
                                text: t('Yes'),
                                classType: 'blue',
                                action: () => handleAction(),
                            },
                        ]}
                    />
                </Modal.Body>
            </Modal>
        </div >)
}
export default ConfirmDeactivateModal