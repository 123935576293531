import { sortByTypeType } from '../../utils/constants';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi';

type SortIconProps = {
  sortByType: string
}

const SortIcon = (props: SortIconProps) => {
  const { sortByType } = props;
  return sortByType === sortByTypeType.DESC ? <HiOutlineChevronDown className='pl-1' size={20} /> : <HiOutlineChevronUp className='pl-1' size={20} />;
}

export default SortIcon;
