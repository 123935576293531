import userSlice from './slice/userSlice';
import conversationSlice from './slice/conversationSlice';
import orgSlice from './slice/orgSlice';
import uploadSlice from './slice/uploadSlice';

const userAction = userSlice.actions;
const conversationAction = conversationSlice.actions;
const orgAction = orgSlice.actions;
const uploadAction = uploadSlice.actions;

export { userAction, conversationAction, orgAction, uploadAction };
