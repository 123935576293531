import { Dropdown } from 'flowbite-react';
import './style.scss';
import { HiDotsVertical } from 'react-icons/hi';
import { filter, flatten, isEmpty } from 'lodash';

const Actions = ({ children }: any) => {
  const showChildren = filter(flatten([children]), (c: any) => c?.props?.show);
  const hileChildren = filter(flatten([children]), (c: any) => !c?.props?.show);

  return (
    <div className="more-action flex sm:mr-2">
      {showChildren}
      {!isEmpty(hileChildren) && (
        <Dropdown
          className='more-action-dropdown'
          renderTrigger={() => (
            <div className="p-1.5 rounded mr-1.5 icon">
              <HiDotsVertical className="w-5 h-5" />
            </div>
          )}
          label={undefined}
        >
          {hileChildren}
        </Dropdown>
      )}
    </div>
  );
};

Actions.Item = ({ action, custom, label, show, icon, cursor = true }: any) => {
  if (show) {
    return (
      <div className={`${cursor ? 'cursor-pointer	' : 'cursor-default'} p-1.5 rounded mr-1.5 icon flex items-center`} onClick={action}>
        {icon}
      </div>
    );
  } else {
    return <Dropdown.Item onClick={action}>{custom || label} </Dropdown.Item>;
  }
};

export default Actions;
