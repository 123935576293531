import SelectOrganization from 'components/selectOrg';
import UserProfile from 'components/userProfile';
import { useLocation, useNavigate } from 'react-router-dom';
import './header.scss';
import { ORG_COOKIE_KEY, pageType, routePaths, tokenKey } from 'utils/constants';
import { HiMail } from 'react-icons/hi';
import Hightlight from './hightlight';
import Popover from './popover';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { getConversations } from 'api/messagesApi';
import { useDispatch, useSelector } from 'react-redux';
import { conversationSelector, orgSelector, userSelector } from 'redux/selectors';
import ConversationItem from './conversationItem';
import { size } from 'lodash';
import Scrollbar from './scrollbar/scrollbar';
import Empty from './empty';
import Svg from './svg';
import { conversationAction } from 'redux/actions';
import { t } from 'i18next';
import { deleteCookie } from '../utils/cookie';

const Header = (props: any) => {
  const logo = require('../assets/image/svg/logo.svg').default;
  const { type } = props;
  const location = useLocation();
  const url = location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(userSelector);
  const { conversations } = useSelector(conversationSelector);
  const { organizationId } = useSelector(orgSelector);

  const { refetch } = useQuery(['getConversations', organizationId], () => getConversations({ limit: 0 }), {
    staleTime: Infinity,
    onSuccess: ({ data }) => dispatch(conversationAction.setConversations(data?.entities)),
    onError: (error: any) => {
      if (error?.response?.status === 401) {
        deleteCookie(tokenKey);
        localStorage.removeItem(ORG_COOKIE_KEY);
      }
    },
  });

  useEffect(() => {
    const intervalConver = setInterval(() => refetch(), 10000);
    return () => clearInterval(intervalConver);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickConversation = (id: any) => {
    if (type === pageType.THERAPIST) {
      navigate(routePaths.THERAPIST_MESSAGES + '/' + id);
    } else navigate(routePaths.PATIENT_MESSAGES + '/' + id);
  };

  const handleNavigateMessage = () => {
    if (type === pageType.THERAPIST) {
      navigate(routePaths.THERAPIST_MESSAGES);
    } else navigate(routePaths.PATIENT_MESSAGES);
  };
  const countHighlightMessage = () =>
    size(conversations?.filter((convers: any) => convers.messages.find((mess: any) => !mess.isView && mess.userId !== userInfo.id)));

  return (
    <div className="flex flex-row items-center justify-between py-3 flex-wrap header bg-tertiary fixed z-10 w-ful hidden-mobile">
      <div className="flex flex-1 justify-between h-8 px-3 items-center">
        <div className="flex">
          <div className="logo-container">
            <button
              className="logo pl-3 h-full w-full border-gray-200 pt-6 pb-6 flex items-center cursor-pointer"
              onClick={() => navigate(routePaths.DASHBOARD_PAGE)}
            >
              <img alt="MileMarker logo" className="mr-3" src={logo} />
            </button>
          </div>
          {userInfo.isActive && (
  <div className="ml-1 flex items-center">
            <SelectOrganization type={type} />
          </div>
          )}
        
        </div>
        <div className="flex items-center relative">
          {/* {!url.includes(routePaths.THERAPIST_MESSAGES) && ( */}
                    {userInfo.isActive && (

          <Popover>
            <button className="mr-4 p-1 relative">
              {!!countHighlightMessage() && <Hightlight count={countHighlightMessage()} className="absolute right-0 top-0" />}
              <HiMail className="text-white w-7 h-7" />
            </button>
            <div className="w-80 top-11 right-2 pb-4">
              {!!size(conversations) && (
                <Scrollbar className="max-h-96" name="conversation">
                  {conversations?.map((item: any) => (
                    <ConversationItem key={item.id} item={item} onClick={() => handleOnClickConversation(item.id)} clientWidth={230} />
                  ))}
                </Scrollbar>
              )}
              {!size(conversations) && (
                <Empty
                  icon={<Svg name="patients-icon" className="text-gray-300 w-auto h-[76px]" />}
                  title={t('noConversation')}
                  note={t('reachOut')}
                  className="border-0 mt-4"
                  onClick={handleNavigateMessage}
                  lableButton={t('goToMessage')}
                />
              )}
            </div>
          </Popover>
                    )}
          {/* )} */}
          <div className="user-profile">
            <UserProfile />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
