import { Navbar } from "flowbite-react"
import React from "react"

interface CTALinkProps extends React.ButtonHTMLAttributes<HTMLSpanElement> {
   label: string
}

const CTALink: React.FC<CTALinkProps> = (props) => {
   const { label, className, ...restProps } = props

   return (
      /* eslint-disable react/jsx-props-no-spreading */
      <span className={(className || '')?.concat(' inline-block list-none cursor-pointer font-semibold')} {...restProps}>
         <Navbar.Link className="text-primary text-sm">
            {label}
         </Navbar.Link>
      </span>
   )
}

export default CTALink