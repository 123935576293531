/* eslint-disabled */
import { useTranslation } from 'react-i18next';
import ExerciseItemPdf from './exerciseItemPdf';

const background = require('../../../../assets/image/svg/bg-program-pdf.svg').default;

const ProgramPDF = (props: any) => {
    const { pdfRef, program } = props
    const [t] = useTranslation()
    return (
        <div className="text-xs h-0 overflow-hidden">
            <div ref={pdfRef} className="program-pdf">
                <div className="header w-full">
                    <img alt="MileMarker logo" className="w-full" src={background} />
                </div>
                <div className='bg-gray p-4 flex justify-between items-center'>
                    <div>
                        <div className='text-secondary text-base font-bold'>{program?.name}</div>
                        <div className="text-tertiary text-xs font-normal">{t('status')}: {program?.status} </div>
                    </div>
                    <div>
                        <div className='border px-3 py-1 border-tertiary rounded-md bg-white text-tertiary'>
                            {program?.exercises?.length} {t('exercise(s)')}
                        </div>
                    </div>

                </div>
                <div className='exercise flex flex-col p-4 gap-4'>
                    {program?.exercises?.map((ex: any) => { return <ExerciseItemPdf exercise={ex} /> })}
                </div>
            </div>
        </div>
    )
}
export default ProgramPDF   