/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { createOrganization } from 'api/organizationApi';
import CustomModalHeader from './customModalHeader';
import GroupButton from '../button/groupButton';
import { toast } from 'react-toastify';
import { messageErrors } from '../../utils/utils';
import useUser from 'hooks/useUser';
import { Label, TextInput, Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useRef } from 'react';
import { themModal } from 'utils/theme';

type organizationModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  headerTitle?: string,
  isEdit: boolean,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number,
};
const OrganizationModal = (props: organizationModalProps) => {
  const { openModal, setOpenModal, headerTitle, currentPage, queryClient, setCurrentPage } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const rootRef = useRef(null);

  const mutation = useMutation('organization', { mutationFn: createOrganization });

  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required(t('userManagementPage.requiredField')).max(60, t('organizationModal.maxLength60')),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
  }: any = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });

  const onSubmit = (values: any, props: any) => {
    mutation.mutate(values, {
      onSuccess: () => {
        toggleHandler();
        if (currentPage !== 0) {
          queryClient.invalidateQueries('getOrganizations', { refetchActive: false }, { cancelRefetch: true });
          setCurrentPage(0);
        } else queryClient.invalidateQueries('getOrganizations', { refetchActive: true }, { cancelRefetch: true });
        const message: string = t('organizationPage.saveSuccess');
        toast.success(message);
        fetchCurrentUser();
      },
      onError: error => {
        const message: string = messageErrors(error, t);
        toast.error(message);
      },
    });
  };

  const toggleHandler = () => {
    setOpenModal(!openModal);
    reset();
  };

  return (
    <div ref={rootRef}>
      <Modal
        show={openModal}
        size="md"
        className="h-full-important"
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body theme={{ base: 'flex-12 pb-4 pt-3 px-12' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2 block">
              <Label value={`${t('organizationModal.name')} *`} />
            </div>
            <div className="mb-4">
              <TextInput
                color={errors?.name ? 'failure' : 'gray'}
                id="name"
                type="text"
                placeholder="Exploration Kids"
                {...register('name')}
                helperText={<>{errors?.name?.message}</>}
              />
            </div>
            <GroupButton
              className="items-center justify-center pt-2 pb-2"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.cancel'),
                  classType: 'white',
                  action: () => toggleHandler(),
                },
                {
                  type: 'submit',
                  text: t('modal.save'),
                  classType: 'blue',
                  isLoading: mutation.isLoading,
                },
              ]}
            />
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default OrganizationModal;
