import { HiOutlineUser, HiOutlineOfficeBuilding } from 'react-icons/hi';
import './organizationItem.scss'
import { useTranslation } from 'react-i18next';

const OrganizationItem = (props: any) => {
    const { organization } = props
    const [t] = useTranslation()

    return (
        <div className="organization-item bg-gray-50 rounded-lg flex flex-row p-4">
            <div className="mr-2 organization-item-avt flex justify-center items-center">
                {!organization?.image?.url ? (
                    <div className='flex justify-center items-center'>
                        <div className='organization-item-icon border-2	p-3 rounded-full'>
                            <HiOutlineOfficeBuilding className='w-8 h-8 text-gray-400' />
                        </div>
                    </div>
                ) : (
                    <div className='flex justify-center items-center'>
                        <img className="overflow-hidden rounded-full object-cover" src={organization?.image?.url} alt="organization" />
                    </div>

                )}
            </div>

            <div className='grow organization-item-infor  pl-2 pt-2 pb-2' >
                <div >
                    <p className="truncate text-lg font-semibold mb-1">{organization?.name}</p>

                    <div className='flex items-center'>

                        <HiOutlineUser className=' w-5 h-5 text-gray-500 mr-1' strokeWidth={3} />
                        <p className='text-gray-500'>{organization?.userCount} {organization?.userCount > 1 ? t('Members') : t("Member")}</p>
                    </div>

                </div>
            </div>
        </div>
    )

}
export default OrganizationItem