import { Checkbox } from "flowbite-react"
import './style.scss'

const CheckboxCustom = (props: any) => {
    const { id, label, handleChange } = props
    return (
        <div className="check-box-custom flex items-center gap-2">
            <Checkbox id={id} className='checkbox border-primary' onClick={handleChange} />
            <p className='font-normal text-sm'>{label}</p>
        </div>
    )
}

export default CheckboxCustom