import Button from 'components/button/button';
import { useTranslation } from 'react-i18next';

const Empty = (props: any) => {
  const { onClick, lableButton, note, title, icon, className = '' } = props;
  const [t] = useTranslation();
  return (
    <div className={`bg-white border border-gray-200 rounded-sm flex flex-col items-center p-8 text-center ${className}`}>
      {icon}
      <p className="text-black font-semibold text-xl mt-7 text-header">{title}</p>
      <p className="text-gray-400 mt-2 text-base">{note}</p>
      {onClick && (
        <div className="mt-6 flex justify-center">
          <Button color="primary" label={lableButton} onClick={onClick} />
        </div>
      )}
    </div>
  );
};

export default Empty;
