import { useQuery } from 'react-query';
const Svg = ({ name, className = '' }: any) => {
  const urlFile = require(`../assets/image/svg/${name}.svg`);
  const getSvg = () => fetch(urlFile).then(response => response.text());
  const { data }: any = useQuery(['getsvg', name], () => getSvg(), { staleTime: Infinity });
  let htmlSvg = data?.replace('<svg', `<svg class="${className}" `);
  if (className.includes('text-'))
    htmlSvg = htmlSvg
      ?.replace(new RegExp('fill="(.[^none]*?)"', 'g'), 'fill="currentColor"')
      ?.replace(new RegExp('stroke="(.[^none]*?)"', 'g'), 'stroke="currentColor"');
  return <span dangerouslySetInnerHTML={{ __html: htmlSvg || '' }} />;
};

export default Svg;
