import { useEffect } from 'react';
import { loginPage } from '../utils/utils';
import { useSearchParams } from "react-router-dom";

const LoginPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const prompt = searchParams.get("prompt") ?? 'login';

  useEffect(() => {
    loginPage(prompt);
  }, [prompt]);

  return null;
};

export default LoginPage;
