import { Button } from 'flowbite-react';

type ModalHeaderTypes = {
  title?: string,
  toggle: Function,
};
const CustomModalHeader = (props: ModalHeaderTypes) => {
  const { title, toggle } = props;
  return (
    <div className="flex justify-center rounded-t dark:border-gray-600 p-4 mt-2">
      <h3 className="text-xl font-medium text-gray-900 dark:text-white text-header">{title}</h3>
      <Button className="bg-white border-0 text-black enabled:hover:bg-white p-0 absolute top-1 right-0.5 focus:ring-0" onClick={() => toggle()}>
        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </Button>
    </div>
  );
};
export default CustomModalHeader;
