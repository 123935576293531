import { useEffect, useRef, useState } from 'react';

const Popover = (props) => {
  const wrapperRef = useRef(null);
  const { children } = props;
  const [isShow, setIsShow] = useState(false);

  const handleClickOutside = (events) => {
    if (wrapperRef.current && !wrapperRef.current.contains(events.target)) {
        setIsShow(false)
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const className = 'bg-white border border-border bg-white rounded-b-md shadow-[2px_2px_5px_0_rgba(0,0,0,0.05)] absolute ';

  return (
    <div className="relative flex items-center" ref={wrapperRef}>
      {/*eslint-disable-next-line react/jsx-props-no-spreading*/}
      {children?.[0].type === 'button' && <button {...children?.[0].props} onClick={() => setIsShow(!isShow)} />}
      {children?.[1] && <div className={`${className} ${isShow ? 'block' : 'hidden'} ${children[1].props.className}`} onClick={() => setIsShow(!isShow)}>{children[1].props.children}</div>}
    </div>
  );
};

export default Popover;
