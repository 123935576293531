import ProgramDetail from "../patient/programDetail"

const ProgramTemplateDetail = () => {
    console.log("voodayyy");
    
    return (
        <ProgramDetail isTemplate/>
    )
}

export default ProgramTemplateDetail