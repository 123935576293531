import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  conversations: [],
  messagesCollection: {},
};

const setConversations = (state: any, action: any) => {
  state.conversations = action.payload;
};
const setMessagesCollection = (state: any, action: any) => {
  state.messagesCollection = action.payload;
};

export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setConversations: setConversations,
    setMessagesCollection: setMessagesCollection,
  },
});

export default conversationSlice;
