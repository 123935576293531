// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import userSlice from './slice/userSlice';
import conversationSlice from './slice/conversationSlice';
import orgSlice from './slice//orgSlice';
import uploadSlice from './slice/uploadSlice';

const rootReducer = combineReducers({
  user: userSlice.reducer,
  conversation: conversationSlice.reducer,
  org: orgSlice.reducer,
  upload: uploadSlice.reducer
});

export default rootReducer;
