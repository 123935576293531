import SidebarComponent from 'components/layouts/sidebar';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from '../redux/selectors';
import { Spinner } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import Header from 'components/header';
import { roleName, routePaths } from 'utils/constants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const DashboardPage = () => {
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const navigate = useNavigate();
  const organization = userInfo?.organizations?.find((o: any) => o.id === organizationId && !!o.role);
  const isTherapist = [roleName.THERAPIST, roleName.ORGANIZATION_ADMIN].includes(organization?.role?.displayName);
  const isPatient = organization?.role?.displayName === roleName.PATIENT;
  const building = require('../../src/assets/image/svg/building.svg').default;

  useEffect(() => {
    if (isTherapist) navigate(routePaths.THERAPIST_PATIENTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTherapist]);


  useEffect(() => {
    if (isPatient) navigate(routePaths.PATIENT_DASHBOARD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPatient]);

  if (!userInfo?.id) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }
  return (
    <div className="w-full flex flex-row justify-center">
      <SidebarComponent isDashboard={true} />
      <div className="w-full h-full flex flex-col justify-between bg-gray-50">
        <div className="w-full mt-dashboard-mb shadow-md">
          <Header />
        </div>
        {userInfo?.organizations.length === 0 && (
          <div className="h-full pt-28">
            <div className="mb-4 flex justify-center">
              <img alt="MileMarker logo" src={building} />
            </div>
            <p className="text-2xl font-semibold text-center mb-4">{t('dashboard.notification')}</p>
            <p className="text-sm font-normal text-center	">{t('dashboard.note')}</p>
          </div>
        )}
      </div>
      <div className="h-screen block" />
    </div>
  );
};
export default DashboardPage;
