import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  uploading: false,
};

const setUploading = (state: any, action: PayloadAction<boolean>) => {
  state.uploading = action.payload;
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setUploading,
  },
});

export default uploadSlice;
