import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import routes from 'router/routes';
import { routeType } from 'utils/proptypes';
import useUser from 'hooks/useUser';
import MainLayout from '../components/layouts/mainLayout';
import { BasicLayout, LoginPage, UpdateProfile, NotFoundPage, ContactSupportPage } from 'pages';
import { Spinner } from 'flowbite-react';
import { useSelector } from 'react-redux';
import { userSelector, orgSelector } from 'redux/selectors';
import { permissionKeys, routePaths } from 'utils/constants';
import { Helmet } from 'react-helmet';
import EmailVerification from 'pages/emailVerification';
import { checkPermission, loginPage } from 'utils/utils';
import { getToken, setRefreshToken, setToken } from 'oauth';
import SignupPage from 'pages/signup';
import { find, flattenDeep, isEmpty, map, uniq } from 'lodash';
import { useMutation } from 'react-query';
import { auth2Token } from 'api/oauthApi';

const Router = () => {
  const isUseSubscription = process.env.REACT_APP_IS_USE_SUBSCRIPTION === 'true';
  const { PLATFORM_ADMIN } = permissionKeys;
  const { fetchCurrentUser } = useUser();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isRequireEmailVerification = process.env.REACT_APP_REQUIRE_EMAIL_VERIFICATION === 'true' || false;
  const isNavigateToEmailVerification = isRequireEmailVerification && !userInfo?.emailVerified;
  const platformAdmin = find(uniq(flattenDeep(map(userInfo?.roles, (r: any) => r?.permissions))), (per: any) => per === PLATFORM_ADMIN);

  useEffect(() => {
    const code: any = new URLSearchParams(window.location.search).get('code');
    if (code) {
      mutAuth2Token.mutate(code);
    } else {
      const token = getToken();
      if (!token) {
        //Use default handling which will send user to the Login Page
        setIsAuthenticated(false);
        setIsLoading(false);
      } else {
        fetchCurrentUser();
        setIsAuthenticated(true);
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mutAuth2Token = useMutation('auth2Token', {
    mutationFn: auth2Token,
    onSuccess: ({ data }) => {
      setToken(data.access_token, data.expires_in);
      setRefreshToken(data.refresh_token, 43200); // 43200 = 720h
      fetchCurrentUser(data.id_token)
        .catch(console.error)
        .finally(() => {
          window.location.replace(window.location.origin);
        });
    },
    onError: _error => {
      loginPage();
    },
  });

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path={routePaths.LOGIN_PAGE} element={<LoginPage />} key={`route-key-login`} />
        <Route path={routePaths.SIGNUP} element={<SignupPage />} key={`route-key-signup`} />
        {!isAuthenticated && <Route path="*" element={<Navigate replace to={routePaths.LOGIN_PAGE} />} key={`route-key-navigate-login`} />}
        {isAuthenticated && isNavigateToEmailVerification && (
          <>
            <Route path={routePaths.EMAIL_VERIFICATION} element={<EmailVerification />} key={`route-key-email-verification`} />
            <Route
              path={routePaths.DASHBOARD_PAGE}
              element={<Navigate replace to={routePaths.EMAIL_VERIFICATION} />}
              key={`route-key-navigate-email-verification`}
            />
          </>
        )}
        {isAuthenticated &&
          routes.map((route, index) => {
            if (isEmpty(userInfo)) return <Route path="*" element={<></>} key={`route-key-waning`} />;

            if (userInfo?.id && !userInfo?.isActive && route.path !== routePaths.LOGOUT_PAGE) {
              return <Route path="*" element={<ContactSupportPage />} key={`route-key-contact-support`} />;
            }

            if (route.path === routePaths.UPDATE_PROFILE && userInfo?.updatedProfile) {
              const element = <Navigate to={routePaths.DASHBOARD_PAGE} />;
              return <Route path={routePaths.UPDATE_PROFILE} element={element} key={`route-key-update-profile`} />;
            }

            if (route.path === routePaths.UPDATE_PROFILE && !userInfo?.updatedProfile) {
              const element = <UpdateProfile isNavigatedFromDashboard={true} />;
              return <Route path={routePaths.UPDATE_PROFILE} element={element} key={`route-key-update-profile`} />;
            }

            if (route.path === routePaths.DASHBOARD_PAGE && userInfo.updatedProfile === null) {
              const element = <Navigate replace to={routePaths.UPDATE_PROFILE} />;
              return <Route path={routePaths.DASHBOARD_PAGE} element={element} key={`route-key-dashoard`} />;
            }

            if (route.path === routePaths.DASHBOARD_PAGE && isEmpty(userInfo.subscriptions) && !platformAdmin && isUseSubscription) {
              const element = <Navigate replace to={routePaths.SUBSCRIPTION} />;
              return <Route path={routePaths.DASHBOARD_PAGE} element={element} key={`route-key-dashoard`} />;
            }

            const checkPerm = checkPermission(userInfo, route.type, route.permissions, organizationId);
            const element = checkPerm ? <Outlet /> : <Navigate to={routePaths.DASHBOARD_PAGE} />;
            return (
              <Route path={route.path} element={element} key={`route-key-${index + 1}`}>
                {getFinalRoute(route, index)}
              </Route>
            );
          })}
        <Route path="*" element={<NotFoundPage />} key={`route-key-not-found`} />
      </Routes>
    </BrowserRouter>
  );
};

const getFinalRoute = (route: routeType, key: number) => {
  let Layout: any;
  if (route.layout === 'MainLayout') {
    Layout = MainLayout;
  } else Layout = BasicLayout;

  return (
    <Route
      path={route.path}
      element={
        <Layout type={route.type} permissions={route.permissions} path={route.path}>
          <Helmet>
            <title>{route.title ? route.title : 'HEDGE Health'}</title>
            <meta name="description" content={'Description'} />
          </Helmet>
          {route.component ? <route.component type={route.type} permissions={route.permissions} /> : <></>}
        </Layout>
      }
      key={`route-key-${key}`}
    />
  );
};

export default Router;
