/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { ORG_COOKIE_KEY, pageType, routePaths } from 'utils/constants';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors';
import { isEmpty, size } from 'lodash';
import Select, { components } from 'react-select';
import useUser from 'hooks/useUser';
import './selectOrg.scss';
import { useTranslation } from 'react-i18next';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Svg from './svg';

const SelectOrganization = (props: any) => {
  const { type } = props;
  const params = useParams();
  const locaion = useLocation();
  const [options, setOptions] = useState([]);
  const { userInfo } = useSelector(userSelector);
  const [optionSelect, setOptionSelect] = useState({});
  const { handleSetCookie } = useUser();
  const [t] = useTranslation();
  const navigate = useNavigate();

  const formatOptionLabel = ({ label, value }: any) => {
    const organization = userInfo.organizations.find((o: any) => o.id === value);
    const countUsers = organization?.userCount || size(organization?.userOrganizations) || 0;
    return (
      <div className="select-label">
        <div>
          <p className="font-light text-name">{label}</p>
        </div>
      </div>
    );
  };

  const handleSelectOrganization = async (event: any) => {
    const option: any = options?.find((o: any) => o.value === event.value);
    if (option) {
      await setOptionSelect(option);
      await handleSetCookie(option.value);
      if ((locaion.pathname.split('/').includes('clinicians') || locaion.pathname.split('/').includes('patients')) && params.id) {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (userInfo) {
      const organizationId = localStorage.getItem(ORG_COOKIE_KEY);
      const optionsMap = userInfo.organizations?.map((o: any) => ({ value: o.id, label: o.name }));
      setOptions(optionsMap);
      const option = optionsMap?.find((o: any) => o.value === organizationId);
      setOptionSelect(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, type]);

  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        <div className="text-base font-semibold	pt-3 px-5">{t('Switch to')}</div>
        <div className="select-options">{props.children}</div>
      </components.MenuList>
    );
  };

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <HiOutlineChevronDown className="h-4 w-4" />
        </components.DropdownIndicator>
      )
    );
  };

  const renderColor = (isSelected: any, isFocused: any) => {
    if ((isSelected && isFocused) || isSelected) {
      return 'rgba(132, 116, 106, 0.2)';
    } else if (isFocused) return 'rgba(132, 116, 106, 0.1)';
    return null;
  };

  const colourStyles = {
    option: (styles: any, { isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: renderColor(isSelected, isFocused),
      };
    },
  };

  return (
    <div className="selected-org-container ">
      {!isEmpty(options) && options.length > 1 && type !== pageType.SITE && (
        <div className="select-org">
          <Select
            className={`block disabled:cursor-not-allowed disabled:opacity-50 text-gray-900 font-light rounded text-xs	`}
            value={optionSelect}
            options={options}
            defaultValue={''}
            onChange={handleSelectOrganization}
            formatOptionLabel={formatOptionLabel}
            isSearchable={false}
            components={{ MenuList: MenuList, DropdownIndicator: DropdownIndicator }}
            styles={colourStyles}
          />
        </div>
      )}
      {!isEmpty(options) && options.length === 1 && type !== pageType.SITE && (
        <div className="select-org select-org-one one bg-tertiary">{formatOptionLabel(optionSelect)}</div>
      )}
      {type === pageType.SITE && (
        <div className="flex site-admin items-center">
          <p className="font-medium site-admin-text text-white text-base ml-2 flex items-center">{t('sidebar.siteAdmin')}</p>
        </div>
      )}
    </div>
  );
};

export default SelectOrganization;
