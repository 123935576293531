import { useEffect, useState } from 'react';
import './style.scss';
import { isArray, isEmpty, map } from 'lodash';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const Select = (props: any) => {
  const { name, placeholder, errors, isHolderLabel, onChange, options, singleValue, value, isMulti = false, isCustomOption = false, formatOptionLabel, hookForm, className = '' } = props;

  const getValue = () => value || options?.filter((o: any) => hookForm?.getValues(name)?.includes(o.value));

  const [focus, setFocus] = useState(!isEmpty(getValue()));
  const fnErrors = errors || hookForm?.formState?.errors || {};

  const handleOnChange = (options: any) => {
    if (hookForm) {
      const values: any = isArray(options) ? map(options, 'value') : options.value;
      hookForm.setValue(name, values);

      if (!isEmpty(values) && !isEmpty(hookForm.formState.errors)) hookForm.clearErrors(name);
    }

    if (onChange) onChange(options);
  };

  useEffect(() => {
    if (value) {
      setFocus(true)
    }
  }, [value])

  return (
    <div className={`flex-1 select-custom relative w-full rounded-md ${isMulti || isCustomOption ? 'select-multi' : ''} ${className}`}>
      {isHolderLabel && <span className={`holder ${focus ? 'label' : ''}`}>{placeholder}</span>}
      <ReactSelect
        className={`react-select ${fnErrors[name] ? 'error bg-red-50' : ''} rounded-md`}
        classNamePrefix="react-select"
        closeMenuOnSelect={!isMulti}
        components={singleValue ? { ...animatedComponents, SingleValue: singleValue } : { ...animatedComponents }}
        defaultValue={getValue()}
        formatOptionLabel={formatOptionLabel}
        isMulti={isMulti}
        onBlur={() => setFocus(!isEmpty(getValue()))}
        onChange={handleOnChange}
        onFocus={() => setFocus(true)}
        options={options}
        placeholder={!isHolderLabel ? placeholder : ''}
        value={value && getValue()}
      />
      {fnErrors[name] && <div className={`text-red-600 text-xs font-normal mt-2 veri-modal height-16`}>{fnErrors[name]?.message}</div>}
    </div>
  );
};

export default Select;
