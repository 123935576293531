import cn from 'classnames';

const GroupButton = (props: any) => {
  const { className, buttons } = props;
  return (
    <div className={cn('flex mt-2', className)}>
      {buttons?.map((button: any, index: number) => {
        let classType = '';
        if (button?.classType === 'white') {
          classType = 'text-primary hover:bg-blue-100';
        } else if (button?.classType === 'blue') {
          classType = 'text-white bg-primary hover:bg-primary';
        }
        return (
          <div className={`relative ${index !== 0 ? 'ml-4' : ''}`} key={`key_${index}`}>
            <button
              type={button?.type}
              onClick={button?.action || (() => { })}
              disabled={button?.disabled}
              className={`button ${button?.isLoading ? 'is-loading' : ''} ${classType} border border-primary focus:outline-none font-medium rounded-md text-sm px-5 py-2.5 text-center`}
            >
              {button?.text}
            </button>
          </div>
        );
      })}
    </div>
  );
};
export default GroupButton;
