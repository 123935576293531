import axios from 'axios';
import { httpMethods } from 'utils/constants';
import { headerType } from 'utils/proptypes';
import urls from './urls';
import { getRefreshToken, getToken, setRefreshToken, setToken } from 'oauth';
import { loginPage } from '../utils/utils';

const BaseApi = () => {
  const execute = async (method: string, endpoint: string, headers: headerType = {}, body: object = {}) => {
    const token = await getToken();
    axios.defaults.baseURL = urls.baseUrl;
    const requestConfig = {
      ...(method === httpMethods.GET && { params: body }),
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: generateAuthToken(token),
      },
    };
    let result: any = null;
    if (method === httpMethods.GET) {
      result = axios.get(endpoint, requestConfig);
    } else if (method === httpMethods.POST) {
      result = axios.post(endpoint, body, requestConfig);
    } else if (method === httpMethods.PUT) {
      result = axios.put(endpoint, body, requestConfig);
    } else if (method === httpMethods.PATCH) {
      result = axios.patch(endpoint, body, requestConfig);
    } else {
      result = axios.delete(endpoint, requestConfig);
    }
    return result
      ?.then((response: any) => {
        return response;
      })
      .catch(async (error: any) => {
        if (error?.response?.status === 401) {
          const refreshToken = getRefreshToken();
          const domain = process.env.REACT_APP_OAUTH_DOMAIN || '';
          const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID || '';
          const tokenRequestBody = new URLSearchParams({
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: clientId,
            scope: 'openid profile email offline_access',
          } as any);
          const requestConfig = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
          const { data }: any = await axios.post(`${domain}/oauth2/token`, tokenRequestBody.toString(), requestConfig).catch(async () => {
            return await loginPage();
          });
          setToken(data?.access_token, data?.expires_in);
          setRefreshToken(data?.refresh_token, 43200); // 43200 = 720h
          return execute(method, endpoint, headers, body);
        }
        throw error;
      });
  };

  const generateAuthToken = (token: any) => {
    return `Bearer ${token}`;
  };

  return {
    methods: httpMethods,
    urls,
    execute,
  };
};

export default BaseApi;
