import { Textarea, TextInput } from 'flowbite-react';
import { useEffect, useState } from 'react';
import './style.scss';
import { omit } from 'lodash';
import { themInput } from 'utils/theme';
import { getSecondsFromHHMMSS, toHHMMSS } from 'utils/utils';

const InputText = (props: any) => {
  const { name, placeholder, hookForm, isHolderLabel, className, disabled, handleKeyPress, type = "text", handleOnBlur, rightIcon, handleClick, rows } = props;
  const {
    formState: { errors },
    register,
    getValues,
  } = hookForm;

  const [focus, setFocus] = useState(!!getValues(name));
  useEffect(() => {
    setFocus(!!getValues(name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues(name)]);

  useEffect(() => {
    if (getValues(name) !== '' && !!getValues(name)) setFocus(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues(name)]);

  const handleOnKeyPress = (e: any) => {
    setFocus(true)
    if (handleKeyPress) {
      handleKeyPress(e)
    }
  }

  const onBlur = (e: any) => {
    setFocus(!!getValues(name));
      if (name === 'duration' && (getValues(name) === '' || getValues(name) === '00:00')) {
                    hookForm.setValue(`duration`, null)

            setFocus(false)
        }
      
    if (handleOnBlur) {
      handleOnBlur(e)
    }
  }

      const handleClickInput = (event: any) => {
        if (name === 'duration') {
            const value = event.target.value;
            const seconds = Math.max(0, getSecondsFromHHMMSS(value));
            const time = toHHMMSS(seconds);
            hookForm.setValue(`duration`, time)
        }
        setFocus(true)
    }

  return (
    <div className={`flex-1 input-text relative ${className || ''}`} onBlur={onBlur}>
      {isHolderLabel && <span className={` ${focus ? 'label' : ''} ${type === 'textarea' ? 'holder-textarea' : 'holder'} `}>{placeholder}</span>}
      {type === 'textarea' ? (
        <Textarea
          id={name}
          rows={rows ? rows :3}
          placeholder={!isHolderLabel ? placeholder : ''}
          onFocus={() => setFocus(true)}
          onClick={() => setFocus(true)}
          theme={themInput}
          /* eslint-disable react/jsx-props-no-spreading */
          {...register(`${name}`)}
          className="text-sm border-border rounded-md"
          color={errors?.[name] ? 'failure' : 'gray'}
        />
      ) : (
        <TextInput
          theme={themInput}
          onFocus={() => setFocus(true)}
          onBlur={() => {
            setFocus(!!getValues(name));
            return register(name).onBlur;
          }}
          onClick={(e) => handleClickInput(e)}
          className="flex-1 items-center rounded-md"
          color={errors[name] ? 'failure' : 'gray'}
          id={name}
          type={type}
          placeholder={!isHolderLabel ? placeholder : ''}
          onKeyPress={e => handleOnKeyPress(e)}
          /* eslint-disable react/jsx-props-no-spreading */
          {...(register ? omit(register(name), 'onBlur') : {})}
          disabled={disabled}
          helperText={<span className="text-xs font-normal">{errors?.[name]?.message}</span>}
        />)}
          {
                rightIcon && (
                    <div onClick={handleClick} className="absolute cursor-pointer right-icon right-1 top-[12px] w-[45px] h-[25px]">
                        {rightIcon}
                    </div>
                )
            }
    </div>
  );
};

export default InputText;
